import React, { useEffect, useState } from 'react';
import moment from 'moment';
import {
  Typography, Grid, TextField, CircularProgress, FormControl, Dialog,
  DialogActions, DialogContent, InputAdornment, DialogTitle, Divider
} from '@material-ui/core';
import InfoIcon from '@material-ui/icons/Info';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import CancelIcon from '@mui/icons-material/Cancel';
import HourglassEmptyIcon from '@mui/icons-material/HourglassEmpty';
import authService from '../Services/auth/auth-service';
import estoqueService from '../Services/Estoque/estoque-service';
import MenuComponent from '../components/menu/menu-component';
import BlueButton from '../components/button/BlueButton';
import Inventory2Icon from '@mui/icons-material/Inventory2';
import NovaSolicitacaoReversa from './components/nova-solicitacao-reversa';
import AssignmentIcon from '@mui/icons-material/Assignment';
import Button from '@material-ui/core/Button';
import Box from '@mui/material/Box';
import { useMediaQuery } from '@material-ui/core'
import { useTheme } from '@material-ui/core/styles'
import usuarioService from '../Services/Usuario/usuario-service';
import IconButton from '@material-ui/core/IconButton';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import { makeStyles } from '@material-ui/core/styles';
import { Paper } from '@material-ui/core';
import CustomAlert from '../components/alert/CustomAlert';
import SearchIcon from '@material-ui/icons/Search';
import CloseIcon from '@material-ui/icons/Close';
import InventoryIcon from '@mui/icons-material/Inventory';
import CustomTablePagination from '../components/pagination/pagination-component';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    padding: '0 80px 40px',
    fontFamily: 'Mont-Regular, sans-serif',
    [theme.breakpoints.down('sm')]: {
      padding: '0 20px 40px',
    },
  },
  searchIcon: {
    marginRight: 4,
  },
  searchInput: {
    flex: 1,
    paddingVertical: 8,
    fontSize: 18,
  },
  paper: {
    marginBottom: 20,
    display: 'flex',
    flexDirection: 'column',
    padding: '15px 20px 15px',
    borderRadius: 10,
  },
  tituloStyle: {
    fontFamily: 'Mont, sans-serif',
    color: '#209BDE',
    fontSize: '22px',
    textAlign: 'start',
    padding: '0 0px',
    marginBottom: '5px'
  },
  subtituloStyle: {
    fontFamily: 'Mont, sans-serif',
    color: '#7F7F7F',
    fontSize: '16px',
    fontWeight: 'inherit',
    textAlign: 'start',
    padding: '0 0px',
    marginTop: '0px'
  },
  mensagemStyle: {
    fontFamily: 'Mont, sans-serif',
    color: '#209BDE',
    fontSize: '22px',
    textAlign: 'start',
    padding: '0 0px',
  },
  itemTexto: {
    fontFamily: 'Mont-Regular, sans-serif',
    fontSize: '16px',
  },
  infos: {
    flex: 1,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  search: {
    marginBottom: 40,
  },
  item: {
    marginBottom: 30,
  },
  avatar: {
    padding: 6,
    borderRadius: 50,
    backgroundColor: theme.palette.grey[500],
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    marginRight: 5,
  },
  row: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
  loadingContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: '100vh',
  }
}));

export default function EstoqueSolicitacaReversaPage({ microSigaID, handleCloseReversaGestorTecnico, nomeTecnico }) {
  const classes = useStyles();
  const [solicitacaoReversa, setSolicitacaoReversa] = useState([]);
  const [showDetails, setShowDetails] = useState(false);
  const [openModalDetalhesReversa, setOpenModalDetalhesReversa] = useState(false);
  const [reversaSelecionada, setReversaSelecionada] = useState(null);
  const mobile = useMediaQuery(useTheme().breakpoints.down(800))
  const [tableKey, setTableKey] = useState(0);
  const [dadosItensSeriesReversa, setdadosItensSeriesReversa] = useState([]);
  const [modalDetalhesItensSeriesReversa, setModalDetalhesItensSeriesReversa] = useState(false);
  const [usuarioEstoqueAtivo, setUsuarioEstoqueAtivo] = useState(false);
  const [page, setPage] = useState(0);
  const [pageSize, setPageSize] = useState(mobile ? 10 : 8);
  const [totalDados, setTotalDados] = useState(0);
  const [carregandoTable, setCarregandoTable] = useState(false);
  const [dataInicial, setDataInicial] = useState('');
  const [dataFinal, setDataFinal] = useState('');
  const [abrirAlert, setAbrirAlert] = useState(false);
  const [mensagemAlert, setMensagemAlert] = useState('');
  const [tipoAlert, setTipoAlert] = useState('error');
  const [loading, setLoading] = useState(true);
  const permissaoTCL = authService.getUserInfo().usuario.classificacaoPrestador;
  const tecnicoPrestador = authService.getUserInfo().usuario.tecnico;
  const [isSearchPerformed, setIsSearchPerformed] = useState(false);
  const [searchTerm, setSearchTerm] = useState('');
  const [searchTermReversa, setSearchTermReversa] = useState('');
  const [filteredProducts, setFilteredProducts] = useState(reversaSelecionada?.solicitacaoReversaEstoques || []);
  const [filteredProductsReversa, setFilteredProductsReversa] = useState(dadosItensSeriesReversa);
  const [carregandoNFReversaId, setCarregandoNFReversaId] = useState(null);
  const [pageReversa, setPageReversa] = useState(0);
  const [rowsPerPageReversa, setRowsPerPageReversa] = useState(8);
  const [rowsPerPage, setRowsPerPage] = useState(8);
  const [numeroSolicitacao, setNumeroSolicitacao] = useState('');

  const buscarUsuarioEstoqueAtivo = async () => {
    const response = await usuarioService.usuarioEstoqueAtivo(authService.getUserInfo().usuario.id);
    if (response.error) {
      return;
    } else {
      setUsuarioEstoqueAtivo(response);
    }
  }

  const handleClick = () => {
    setShowDetails(!showDetails);
  };

  const handleClose = () => {
    setShowDetails(false);
  };

  const abrirModalDetalhesReversa = (rowData) => {
    setOpenModalDetalhesReversa(true)
    setReversaSelecionada(rowData)
  }

  const fecharModalDetalhesContestacao = () => {
    setSearchTerm('');
    setPage(0);
    setFilteredProducts(reversaSelecionada?.solicitacaoReversaEstoques || []);
    setOpenModalDetalhesReversa(false);
  }

  const fecharModalDetalhesItensSeriesReversa = () => {
    setPage(0);
    setSearchTerm('');
    setModalDetalhesItensSeriesReversa(false)
  }

  const handleDateFilterSearch = () => {
    if (!dataInicial || !dataFinal) {
      setMensagemAlert({ title: 'Erro', body: 'Por favor, preencha as datas de início e final.' });
      setTipoAlert('fail');
      setAbrirAlert(true);
      return;
    }

    if (moment(dataInicial).isAfter(dataFinal)) {
      setMensagemAlert({ title: 'Erro', body: 'A data inicial não pode ser maior que a data final.' });
      setTipoAlert('fail');
      setAbrirAlert(true);
      return;
    }

    setIsSearchPerformed(true);
    setPage(0);
    buscarDadosSolicitacaoReversa(dataInicial, dataFinal, 0, pageSize, searchTerm);
  };

  const handleProductSearch = (term) => {
    if (!term) {
      setFilteredProducts(reversaSelecionada?.solicitacaoReversaEstoques || []);
    } else {
      const filtered = reversaSelecionada?.solicitacaoReversaEstoques.filter((item) =>
        item.produtoNome?.toLowerCase().includes(term.toLowerCase()) ||
        item.produtoCodigo?.toLowerCase().includes(term.toLowerCase()) ||
        item.serie?.toLowerCase().includes(term.toLowerCase())
      );
      setFilteredProducts(filtered);
    }
  };

  const handleReversaSearch = (term) => {
    if (!term) {
      setFilteredProductsReversa(dadosItensSeriesReversa);
    } else {
      const filtered = dadosItensSeriesReversa.filter((item) =>
        item.codProduto.toLowerCase().includes(term.toLowerCase()) ||
        item.descProduto.toLowerCase().includes(term.toLowerCase())
      );
      setFilteredProductsReversa(filtered);
    }
  };

  const handleClear = () => {
    setDataInicial('');
    setDataFinal('');
    setIsSearchPerformed(false);
    buscarDadosSolicitacaoReversa('', '', page, pageSize, '');
  };

  const handlePageChange = (event, newPage) => {
    setPage(newPage);
  };

  const handleRowsPerPageChange = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handlePageChangeReversa = (event, newPage) => {
    setPageReversa(newPage);
  };

  const handleRowsPerPageChangeReversa = (event) => {
    setRowsPerPageReversa(parseInt(event.target.value, 10));
    setPageReversa(0);
  };

  const paginatedProducts = filteredProducts.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage);

  const buscarDadosSolicitacaoReversa = async (dataInicial, dataFinal, page, pageSize, searchTerm) => {
    setCarregandoTable(true);

    if (dataInicial && dataFinal && moment(dataInicial).isAfter(dataFinal)) {
      setMensagemAlert({ title: 'Falha!', body: 'Data inicial não pode ser maior que a data final!' });
      setTipoAlert('fail');
      setAbrirAlert(true);
      setDataInicial('');
      setDataFinal('');
      setCarregandoTable(false);
      return;
    }
    const dados = {
      dataInicial: dataInicial ? dataInicial : '',
      dataFinal: dataFinal ? dataFinal : '',
      pagina: page ? page : 0,
      limite: pageSize ? pageSize : 8,
      nome: searchTerm ? searchTerm : '',
    };

    const estoqueLista = await estoqueService.listarSolicitacoesReversa(dados, microSigaID);

    if (estoqueLista.dados) {
      const dadosAjustados = estoqueLista.dados.map(solicitacao => ({
        ...solicitacao,
        status: solicitacao.status === 'EmAnalise' ? 'Em Análise' :
          solicitacao.status === 'Aprovada' ? 'Aprovada' :
            solicitacao.status === 'Reprovada' ? 'Reprovada' :
              solicitacao.status
      }));

      setSolicitacaoReversa(dadosAjustados);
      setTotalDados(estoqueLista.total);
    }

    setCarregandoTable(false);
  };

  const itensSeriesReversa = async (id, numeroSolicitacao) => {
    try {
      setNumeroSolicitacao(numeroSolicitacao);
      setCarregandoNFReversaId(id);
      let area = authService.getUserInfo().usuario.tz_area_atendimento_microsigaid;
      let dadosItensSeries = await estoqueService.itensSeriesReversa(id, microSigaID ? microSigaID : area);

      if (dadosItensSeries.length > 0) {
        setdadosItensSeriesReversa(dadosItensSeries);
        setFilteredProductsReversa(dadosItensSeries);
        setModalDetalhesItensSeriesReversa(true);
      } else {
        setMensagemAlert({ title: 'Falha!', body: 'Nenhum item encontrado para esta NF Reversa.' });
        setTipoAlert('fail');
        setAbrirAlert(true);
      }
    } catch (error) {
      setMensagemAlert({ title: 'Falha!', body: 'Erro ao carregar NF Reversa. Por favor, tente novamente.' });
      setTipoAlert('fail');
      setAbrirAlert(true);
    } finally {
      setCarregandoNFReversaId(null);
    }
  };

  const mudarPagina = (event, newPage) => {
    setPage(newPage);
    buscarDadosSolicitacaoReversa(dataInicial, dataFinal, newPage, pageSize, searchTerm);
  };

  const mudarTamanhoPagina = (event) => {
    const newPageSize = parseInt(event.target.value, 10);
    setPageSize(newPageSize);
    setPage(0);
    buscarDadosSolicitacaoReversa(dataInicial, dataFinal, 0, newPageSize, searchTerm);
  };

  const StatusBadge = ({ status }) => {
    let statusText = '';
    let bgColor = '';
    let IconComponent = null;

    switch (status) {
      case 'Aprovada':
        statusText = 'Aprovada';
        bgColor = 'rgba(3, 201, 169, 1)';
        IconComponent = CheckCircleIcon;
        break;
      case 'Em Análise':
        statusText = 'Em Análise';
        bgColor = '#777';
        IconComponent = HourglassEmptyIcon;
        break;
      case 'Reprovada':
        statusText = 'Reprovada';
        bgColor = 'rgba(255, 99, 132, 1)';
        IconComponent = CancelIcon;
        break;
      default:
        statusText = 'Desconhecido';
        bgColor = '#000';
        IconComponent = InfoIcon;
        break;
    }

    return (
      <div style={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        padding: '8px 0',
        fontSize: '0.9rem',
        fontWeight: '500',
        color: '#fff',
        backgroundColor: bgColor,
        width: '100%',
        textAlign: 'center',
        borderRadius: '4px',
        marginTop: '10px',
      }}>
        <IconComponent style={{ marginRight: '8px', color: '#fff' }} />
        {statusText}
      </div>
    );
  };

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      await buscarDadosSolicitacaoReversa();
      await buscarUsuarioEstoqueAtivo();
      setTableKey(tableKey + 1);

      if (!mobile) {
        setOpenModalDetalhesReversa(false);
        setModalDetalhesItensSeriesReversa(false);
      }
      setLoading(false);
    };

    fetchData();
  }, [mobile]);

  useEffect(() => {
    setFilteredProducts(reversaSelecionada?.solicitacaoReversaEstoques || []);
  }, [reversaSelecionada]);

  return (
    <>
      <MenuComponent />
      <CustomAlert
        open={abrirAlert}
        message={mensagemAlert}
        type={tipoAlert}
        onClose={() => setAbrirAlert(false)}
      />
      <div style={{ margin: 30 }}>
        {loading ? (
          <div className={classes.loadingContainer}>
            <CircularProgress />
          </div>
        ) : (
          !showDetails ? (
            (permissaoTCL !== 2 && usuarioEstoqueAtivo === true && tecnicoPrestador == null || tecnicoPrestador === false) || authService.getUserInfo().usuario.gestor === true ? (
              <>
                <h3 className={classes.tituloStyle}>Solicitação de Reversa</h3>
                <h4 className={classes.subtituloStyle}>
                  <span
                    onClick={() => {
                      const userInfo = authService.getUserInfo();
                      if (userInfo.usuario.gestor) {
                        window.location.assign('/gestor');
                      } else {
                        window.location.assign('/agenda');
                      }
                    }}
                    className={classes.linkStyle}
                    style={{ cursor: 'pointer' }}
                  >
                    Home
                  </span>
                  {' > '}
                  Solicitação de Reversa
                  {authService.getUserInfo().usuario.gestor === true &&
                    nomeTecnico &&
                    nomeTecnico.trim() !== '' ? (
                    <>
                      {' > '}
                      {nomeTecnico}
                    </>
                  ) : null}
                </h4>

                <div style={{ marginBottom: 20 }}>
                  {authService.getUserInfo().usuario.gestor === true &&
                    nomeTecnico &&
                    nomeTecnico.trim() !== '' ? (
                    <Grid item xs={3} sm={1} md={1} style={{ marginBottom: 20 }}>
                      <Button
                        onClick={handleCloseReversaGestorTecnico}
                        style={{ color: '#209BDE' }}
                        startIcon={<ArrowBackIosIcon />}
                      >
                        Voltar
                      </Button>
                    </Grid>
                  ) : null}

                  <Grid container spacing={2} alignItems="center">
                    <Grid item xs={12} sm={authService.getUserInfo().usuario.gestor !== true ? 3 : 4}>
                      <FormControl variant="outlined" fullWidth>
                        <TextField
                          id="start-date"
                          label="Data inicial"
                          type="date"
                          value={dataInicial}
                          onChange={(e) => setDataInicial(e.target.value)}
                          InputLabelProps={{
                            shrink: true,
                          }}
                          variant="outlined"
                          fullWidth
                        />
                      </FormControl>
                    </Grid>
                    <Grid item xs={12} sm={authService.getUserInfo().usuario.gestor !== true ? 3 : 4}>
                      <FormControl variant="outlined" fullWidth>
                        <TextField
                          id="end-date"
                          label="Data final"
                          type="date"
                          value={dataFinal}
                          onChange={(e) => setDataFinal(e.target.value)}
                          InputLabelProps={{
                            shrink: true,
                          }}
                          variant="outlined"
                          fullWidth
                        />
                      </FormControl>
                    </Grid>
                    <Grid item xs={12} sm={authService.getUserInfo().usuario.gestor !== true ? 3 : 4}>
                      <BlueButton
                        variant="contained"
                        color="primary"
                        size="large"
                        onClick={isSearchPerformed ? handleClear : handleDateFilterSearch}
                        fullWidth
                        startIcon={isSearchPerformed ? <CloseIcon /> : <SearchIcon />}
                        style={{ height: 55 }}
                      >
                        {isSearchPerformed ? 'LIMPAR' : 'BUSCAR'}
                      </BlueButton>
                    </Grid>
                    <Grid item xs={12} sm={3}>
                      {authService.getUserInfo().usuario.gestor !== true && (
                        <BlueButton
                          variant="contained"
                          color="primary"
                          size="large"
                          fullWidth
                          onClick={handleClick}
                          style={{ height: 55 }}
                          startIcon={<Inventory2Icon />}
                        >
                          Nova Solicitação
                        </BlueButton>
                      )}
                    </Grid>
                  </Grid>
                </div>

                <Dialog
                  aria-labelledby="customized-dialog-title"
                  onClose={(event, reason) => {
                    if (reason !== 'backdropClick') {
                      fecharModalDetalhesContestacao();
                    }
                  }}
                  open={openModalDetalhesReversa}
                  style={{ marginTop: 45, marginBottom: 10 }}
                  fullWidth
                  maxWidth="md"
                >
                  <DialogTitle>
                    <Grid container alignItems="center">
                      <Grid item xs={10} sm={11}>
                        Detalhes da Reversa
                      </Grid>
                      <Grid item xs={2} sm={1}>
                        <IconButton onClick={fecharModalDetalhesContestacao}>
                          <CloseIcon />
                        </IconButton>
                      </Grid>
                    </Grid>
                  </DialogTitle>
                  <DialogContent dividers>
                    <Box display="flex" flexDirection="column">
                      <Grid item xs={12}>
                        <Paper
                          elevation={3}
                          style={{
                            padding: '10px',
                            borderRadius: '8px',
                            backgroundColor: '#fff',
                            marginBottom: 16
                          }}
                        >
                          <b style={{ color: '#9b9b9b' }}>Peso: {reversaSelecionada?.peso || '-'} kg | Volume: {reversaSelecionada?.volume || '-'}</b>
                        </Paper>
                      </Grid>
                      <Grid container justifyContent="flex-end">
                        <Grid item xs={mobile ? 12 : 5} style={{ marginBottom: 16 }}>
                          <TextField
                            label="Pesquisar"
                            variant="outlined"
                            value={searchTerm}
                            onChange={(e) => {
                              const term = e.target.value;
                              setSearchTerm(term);
                              handleProductSearch(term);
                            }}
                            size="small"
                            fullWidth
                            InputProps={{
                              endAdornment: (
                                <>
                                  {searchTerm ? (
                                    <IconButton
                                      onClick={() => {
                                        setSearchTerm('');
                                        handleProductSearch('');
                                      }}
                                      size="small"
                                    >
                                      <CloseIcon />
                                    </IconButton>
                                  ) : (
                                    <InputAdornment position="end">
                                      <SearchIcon />
                                    </InputAdornment>
                                  )}
                                </>
                              ),
                            }}
                          />
                        </Grid>
                      </Grid>
                    </Box>
                    {carregandoTable ? (
                      <div className={classes.loadingContainer}>
                        <CircularProgress />
                      </div>
                    ) : mobile ? (
                      <Grid container spacing={2}>
                        {paginatedProducts.length > 0 ? (
                          paginatedProducts.map((item, index) => (
                            <Grid item xs={12} key={index}>
                              <Paper style={{ padding: '16px', marginBottom: '5px' }}>
                                <Grid item xs={12} sm={12}>
                                  <strong>Código:</strong> {item.produtoCodigo || '-'}
                                </Grid>
                                <Grid item xs={12} sm={12}>
                                  <strong>Produto:</strong> {(item.produtoNome || '-').toLowerCase().slice(0, 40)}
                                </Grid>
                                <Grid item xs={12} sm={12}>
                                  <strong>Série:</strong> {item.serie || '-'}
                                </Grid>
                                <Grid item xs={12} sm={12}>
                                  <strong>Motivo:</strong> {item.motivo === 'BaseDeTroca' ? 'Base de Troca' : item.motivo || '-'}
                                </Grid>
                              </Paper>
                            </Grid>
                          ))
                        ) : (
                          <Typography
                            variant="body1"
                            align="center"
                            style={{
                              padding: '16px',
                            }}
                          >
                            Nenhum registro para exibir
                          </Typography>
                        )}
                      </Grid>
                    ) : (
                      <Grid container>
                        <Grid item xs={2}>
                          <Typography style={{ fontWeight: 'bold' }}>Código</Typography>
                        </Grid>
                        <Grid item xs={5}>
                          <Typography style={{ fontWeight: 'bold' }}>Produto</Typography>
                        </Grid>
                        <Grid item xs={2}>
                          <Typography style={{ fontWeight: 'bold' }}>Série</Typography>
                        </Grid>
                        <Grid item xs={3}>
                          <Typography style={{ fontWeight: 'bold' }}>Motivo</Typography>
                        </Grid>
                      </Grid>
                    )}
                    {!mobile && (
                      <>
                        <Box style={{ flex: 1, overflowY: 'auto' }}>
                          {paginatedProducts.length > 0 ? (
                            paginatedProducts.map((item, index) => (
                              <Grid container key={index} style={{ padding: '8px 0' }} wrap="nowrap">
                                <Grid item xs={2}>
                                  <Typography className={classes.itemTexto}> {item.produtoCodigo || '-'}</Typography>
                                </Grid>
                                <Grid item xs={5}>
                                  <Typography className={classes.itemTexto}>{(item.produtoNome || '-').toLowerCase().slice(0, 40)}</Typography>
                                </Grid>
                                <Grid item xs={2}>
                                  <Typography className={classes.itemTexto}>{item.serie || '-'}</Typography>
                                </Grid>
                                <Grid item xs={3}>
                                  <Typography className={classes.itemTexto}>{item.motivo === 'BaseDeTroca' ? 'Base de Troca' : item.motivo || '-'}</Typography>
                                </Grid>
                              </Grid>
                            ))
                          ) : (
                            <Typography
                              variant="body1"
                              align="center"
                              style={{ padding: '16px' }}
                            >
                              Nenhum registro para exibir
                            </Typography>
                          )}
                        </Box>
                      </>
                    )}
                    <Grid container justifyContent="center" alignItems="center" spacing={1} style={{ marginTop: 10 }}>
                      <Grid item>
                        {reversaSelecionada?.solicitacaoReversaEstoques?.length > 0 && (
                          <CustomTablePagination
                            count={filteredProducts.length}
                            page={page}
                            rowsPerPage={rowsPerPage}
                            onPageChange={handlePageChange}
                            quantidadeItens={reversaSelecionada?.solicitacaoReversaEstoques?.length || 0}
                            onRowsPerPageChange={handleRowsPerPageChange}
                          />
                        )}
                      </Grid>
                    </Grid>
                  </DialogContent>
                  <DialogActions>
                    <Button
                      onClick={fecharModalDetalhesContestacao}
                      variant="contained"
                      style={{ float: 'right', width: '40%', height: 55 }}
                    >
                      Fechar
                    </Button>
                  </DialogActions>
                </Dialog>

                <Dialog
                  aria-labelledby="customized-dialog-title"
                  open={modalDetalhesItensSeriesReversa}
                  style={{ marginTop: 45, marginBottom: 10 }}
                  onClose={(event, reason) => {
                    if (reason !== 'backdropClick') {
                      fecharModalDetalhesItensSeriesReversa();
                    }
                  }}
                  fullWidth
                  maxWidth="md"
                >
                  <DialogTitle>
                    <Grid container alignItems="center">
                      <Grid item xs={10} sm={11}>
                        Itens da Reversa
                      </Grid>
                      <Grid item xs={2} sm={1}>
                        <IconButton onClick={fecharModalDetalhesItensSeriesReversa}>
                          <CloseIcon />
                        </IconButton>
                      </Grid>
                    </Grid>
                  </DialogTitle>
                  <Divider />
                  <DialogContent dividers>
                    <Box display="flex" flexDirection="column">
                      <Grid item xs={12}>
                        <Paper
                          elevation={3}
                          style={{
                            padding: '10px',
                            borderRadius: '8px',
                            backgroundColor: '#fff',
                            marginBottom: 16
                          }}
                        >
                          <b style={{ color: '#9b9b9b' }}>Número da Reversa:</b> {numeroSolicitacao ? numeroSolicitacao : ""}
                        </Paper>
                      </Grid>
                      <Grid container justifyContent="flex-end">
                        <Grid item xs={mobile ? 12 : 5}>
                          <TextField
                            label="Pesquisar"
                            variant="outlined"
                            size="small"
                            fullWidth
                            value={searchTermReversa}
                            onChange={(e) => {
                              const term = e.target.value;
                              setSearchTermReversa(term);
                              handleReversaSearch(term);
                            }}
                            style={{ marginBottom: '16px' }}
                            InputProps={{
                              endAdornment: (
                                <>
                                  {searchTermReversa ? (
                                    <IconButton
                                      onClick={() => {
                                        setSearchTermReversa('');
                                        handleReversaSearch('');
                                      }}
                                      size="small"
                                    >
                                      <CloseIcon />
                                    </IconButton>
                                  ) : (
                                    <InputAdornment position="end">
                                      <SearchIcon />
                                    </InputAdornment>
                                  )}
                                </>
                              ),
                            }}
                          />
                        </Grid>
                      </Grid>
                    </Box>

                    {carregandoTable ? (
                      <div className={classes.loadingContainer}>
                        <CircularProgress />
                      </div>
                    ) : mobile ? (
                      <Grid container spacing={2}>
                        {filteredProductsReversa.length > 0 ? (
                          filteredProductsReversa.map((item, index) => (
                            <Grid item xs={12} key={index}>
                              <Paper style={{ padding: '16px', marginBottom: '5px' }}>
                                <Grid item xs={12} sm={12}>
                                  <strong>Código:</strong> {item.codProduto || '-'}
                                </Grid>
                                <Grid item xs={12} sm={12}>
                                  <strong>Produto:</strong> {item.descProduto || '-'}
                                </Grid>
                                <Grid item xs={12} sm={12}>
                                  <strong>Quantidade:</strong> {item.quantidade || '-'}
                                </Grid>
                              </Paper>
                            </Grid>
                          ))
                        ) : (
                          <Grid item xs={12}>
                            <Typography variant="body1" align="center" style={{ padding: '16px' }}>
                              Nenhum registro para exibir
                            </Typography>
                          </Grid>
                        )}
                      </Grid>
                    ) : (
                      <>
                        <Grid container>
                          <Grid item xs={3}>
                            <Typography style={{ fontWeight: 'bold' }}>Código</Typography>
                          </Grid>
                          <Grid item xs={7}>
                            <Typography style={{ fontWeight: 'bold' }}>Produto</Typography>
                          </Grid>
                          <Grid item xs={2}>
                            <Typography style={{ fontWeight: 'bold' }}>Quantidade</Typography>
                          </Grid>
                        </Grid>
                        <Divider style={{ margin: '8px 0' }} />
                        <Box style={{ flex: 1, overflowX: 'auto' }}>
                          {filteredProductsReversa.length > 0 ? (
                            filteredProductsReversa.map((item, index) => (
                              <Box key={index} style={{ marginBottom: '8px' }}>
                                <Grid container key={index} style={{ padding: '8px 0' }} wrap="nowrap">
                                  <Grid item xs={3}>
                                    <Typography variant="body1" noWrap>{item.codProduto || '-'}</Typography>
                                  </Grid>
                                  <Grid item xs={7}>
                                    <Typography variant="body1" noWrap>{item.descProduto || '-'}</Typography>
                                  </Grid>
                                  <Grid item xs={2}>
                                    <Typography variant="body1" noWrap>{item.quantidade || '-'}</Typography>
                                  </Grid>
                                </Grid>
                              </Box>
                            ))
                          ) : (
                            <Typography variant="body1" align="center" style={{ padding: '16px' }}>
                              Nenhum registro para exibir
                            </Typography>
                          )}
                        </Box>
                      </>
                    )}

                    <Grid container justifyContent="center" alignItems="center" spacing={1} style={{ marginTop: 10 }}>
                      <Grid item>
                        {filteredProductsReversa.length > 0 && (
                          <CustomTablePagination
                            count={filteredProductsReversa.length}
                            page={pageReversa}
                            rowsPerPage={rowsPerPageReversa}
                            onPageChange={handlePageChangeReversa}
                            quantidadeItens={filteredProductsReversa.length}
                            onRowsPerPageChange={handleRowsPerPageChangeReversa}
                          />
                        )}
                      </Grid>
                    </Grid>
                  </DialogContent>
                  <Divider />
                  <DialogActions>
                    <Button onClick={fecharModalDetalhesItensSeriesReversa} variant="contained" style={{ float: 'right', width: '40%', height: 55 }}>
                      Fechar
                    </Button>
                  </DialogActions>
                </Dialog>

                <Paper className={classes.paper} >
                  {carregandoTable ? (
                    <div className={classes.loadingContainer}>
                      <CircularProgress />
                    </div>
                  ) : solicitacaoReversa.length === 0 ? (
                    <Grid container justifyContent="center" alignItems="center" >
                      <Typography variant="subtitle1" color="textSecondary">
                        Não há pedidos de reversas.
                      </Typography>
                    </Grid>
                  ) : (
                    <Grid container spacing={1} alignItems="stretch">
                      {solicitacaoReversa.map((rowData, index) => (
                        <Grid item key={index} xs={12} md={3} style={{ width: '100%', maxWidth: '100%' }}>
                          <Paper style={{ padding: '16px', flex: '1 0 auto' }}>
                            <Typography className={classes.itemTexto}> Solicitação: {rowData.id} </Typography>
                            <Typography className={classes.itemTexto}> Data de Cadastro: {moment(rowData.dataCadastro).format('DD/MM/YYYY')}</Typography>
                            <Grid item xs={12} sm={12}>
                              <StatusBadge status={rowData.status} />
                            </Grid>
                            <Grid container spacing={2} justifyContent="center" style={{ marginTop: '5px' }}>
                              <Grid item xs={12}>
                                <BlueButton
                                  variant="contained"
                                  color="primary"
                                  size="large"
                                  style={{ marginBottom: '1px', width: '100%' }}
                                  onClick={() => abrirModalDetalhesReversa(rowData)}
                                  startIcon={<AssignmentIcon />}
                                >
                                  Detalhes da Reversa
                                </BlueButton>
                              </Grid>
                              <Grid item xs={12}>
                                <BlueButton
                                  variant="contained"
                                  color="primary"
                                  size="large"
                                  style={{ marginBottom: '1px', width: '100%' }}
                                  onClick={() => rowData.notaFiscalReversa && itensSeriesReversa(rowData.notaFiscalReversa.id, rowData.id)}
                                  startIcon={carregandoNFReversaId === rowData.notaFiscalReversa?.id ? <CircularProgress size={20} /> : <InventoryIcon />}
                                  disabled={carregandoNFReversaId === rowData.notaFiscalReversa?.id || rowData.status !== 'Aprovada'}
                                >
                                  {carregandoNFReversaId === rowData.notaFiscalReversa?.id ? 'Carregando...' : 'NF Reversa'}
                                </BlueButton>
                              </Grid>
                            </Grid>
                          </Paper>
                        </Grid>
                      ))}
                    </Grid>
                  )}
                  {solicitacaoReversa.length > 0 && (
                    <Grid container justifyContent="center" alignItems="center" spacing={1} style={{ marginTop: 10 }}>
                      <Grid item>
                        <CustomTablePagination
                          count={totalDados}
                          page={page}
                          rowsPerPage={pageSize}
                          onPageChange={mudarPagina}
                          quantidadeItens={totalDados}
                          onRowsPerPageChange={mudarTamanhoPagina}
                        />
                      </Grid>
                    </Grid>
                  )}
                </Paper>

              </>
            ) : (
              <>
                <Paper className={classes.paper}>
                  <Grid container justifyContent="center">
                    <h2 className={classes.mensagemStyle} style={{ padding: 30 }}>Você não tem permissão para acessar esta página!</h2>
                  </Grid>
                </Paper>
              </>
            )
          ) : (
            <NovaSolicitacaoReversa handleClose={handleClose} />
          )
        )}
      </div>
    </>
  );
}