import React, { useEffect, useState } from 'react';
import {
  useMediaQuery, useTheme,
  CircularProgress, TextField, Typography,
  InputAdornment, IconButton,
  Accordion, AccordionSummary, AccordionDetails
} from '@material-ui/core';
import MenuComponent from '../../components/menu/menu-component';
import auditoriaService from '../../Services/Auditoria/auditoria-service';
import BlueButton from '../../components/button/BlueButton';
import authService from '../../Services/auth/auth-service';
import { Paper, Grid } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import CustomTablePagination from '../../components/pagination/pagination-component.js';
import EstoquePage from '../../estoque/estoque-page';
import NotasFiscaisPage from '../../notafiscal/nota-fiscal-page';
import EstoqueSolicitacaReversaPage from '../../estoque/solicitacao-reversa-page';
import ServicosPage from '../../servicos/servicos-page';
import PerfilTecnicoPage from '../../perfilTecnico/perfil-tecnico-page';
import debounce from 'lodash/debounce';
import HistoricoEstoquePage from '../../estoque/historico-estoque-page.js';

// Icones
import InventoryIcon from '@mui/icons-material/Inventory';
import LockOpenIcon from '@mui/icons-material/LockOpen';
import GroupIcon from '@mui/icons-material/Group';
import LocalShippingIcon from '@material-ui/icons/LocalShipping';
import ClearIcon from '@material-ui/icons/Clear';
import AssignmentIcon from '@material-ui/icons/Assignment'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import SearchIcon from '@material-ui/icons/Search';
import TimelineIcon from '@mui/icons-material/Timeline';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    padding: '0 80px 40px',
    [theme.breakpoints.down('sm')]: {
      padding: '0 20px 40px',
    },
  },
  itemTexto: {
    fontFamily: 'Mont-Regular, sans-serif',
    fontSize: '16px',
  },
  searchIcon: {
    marginRight: 4,
  },
  searchInput: {
    flex: 1,
    paddingVertical: 8,
    fontSize: 18,
  },
  paper: {
    marginBottom: 20,
    display: 'flex',
    flexDirection: 'column',
    padding: '15px 20px 15px',
    borderRadius: 10,
  },
  tituloStyle: {
    fontFamily: 'Mont, sans-serif',
    color: '#209BDE',
    fontSize: '22px',
    textAlign: 'start',
    padding: '0 0px',
    marginBottom: '5px'
  },
  textoDetalhes: {
    marginBottom: '4px',
  },
  mensagemStyle: {
    fontFamily: 'Mont, sans-serif',
    color: '#209BDE',
    fontSize: '22px',
    textAlign: 'start',
    padding: '0 0px',
  },
  subtituloStyle: {
    fontFamily: 'Mont, sans-serif',
    color: '#7F7F7F',
    fontSize: '16px',
    fontWeight: 'inherit',
    textAlign: 'start',
    padding: '0 0px',
    marginTop: '0px'
  },
  infos: {
    flex: 1,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  search: {
    marginBottom: 40,
  },
  item: {
    marginBottom: 30,
  },
  avatar: {
    padding: 6,
    borderRadius: 50,
    backgroundColor: theme.palette.grey[500],
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    marginRight: 5,
  },
  row: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
  loadingContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: '100vh'
  }
}));

export default function GestorTecnicoPage() {
  const classes = useStyles();
  const mobile = useMediaQuery(useTheme().breakpoints.down(800));
  const [tecnicos, setTecnicos] = useState([]);
  const [estoque, setEstoque] = useState(false);
  const [ordemServico, setOrdemServico] = useState(false);
  const [notaFiscal, setNotaFiscal] = useState(false);
  const [reversa, setReversa] = useState(false);
  const [solicitacaoCadastro, setSolicitacaoCadastro] = useState(false);
  const [microSigaID, setMicroSigaID] = useState('');
  const [page, setPage] = useState(0);
  const [pageSize, setPageSize] = useState(8);
  const [totalDados, setTotalDados] = useState(0);
  const [carregandoTable, setCarregandoTable] = useState(false);
  const [permissaoGestor, setPermissaoGestor] = useState(0);
  const [tableKey, setTableKey] = useState(0);
  const [pesquisa, setPesquisa] = useState('');
  const [showDetails, setShowDetails] = useState(false);
  const [nomeTecnico, setNomeTecnico] = useState('');
  const [loading, setLoading] = useState(true);
  const [expandedId, setExpandedId] = useState(null);
  const [idTecnico, setIdTecnico] = useState('');
  const [historicoEstoque, setHistoricoEstoque] = useState(false);

  const handleExpand = (id) => {
    setExpandedId(expandedId === id ? null : id);
  };

  const dadosTecnicos = async (page, pageSize, pesquisa) => {
    setCarregandoTable(true);
    const dados = {
      pagina: page || 0,
      limite: pageSize || 8,
      nome: pesquisa || '',
    };

    const response = await auditoriaService.listarUsuariosGestor(dados);

    if (response.dados) {
      setTecnicos(response.dados);
      setTotalDados(response.total);
    }
    setCarregandoTable(false);
  };

  const handleOrdemServico = (tz_area_atendimento_microsigaid, nomeTecnico) => {
    setOrdemServico(true);
    setShowDetails(true);
    setNomeTecnico(nomeTecnico);
    setMicroSigaID(tz_area_atendimento_microsigaid);
  };

  const handleEstoque = (tz_area_atendimento_microsigaid, nomeTecnico, idTecnico) => {
    setEstoque(true);
    setNomeTecnico(nomeTecnico);
    setShowDetails(true);
    setIdTecnico(idTecnico);
    setMicroSigaID(tz_area_atendimento_microsigaid);
  };

  const handleHistoricoEstoque = (tz_area_atendimento_microsigaid, nomeTecnico, idTecnico) => {
    setHistoricoEstoque(true);
    setNomeTecnico(nomeTecnico);
    setShowDetails(true);
    setIdTecnico(idTecnico);
    setMicroSigaID(tz_area_atendimento_microsigaid);
  };

  const handleCloseOrdemServico = () => {
    setOrdemServico(false);
    limparPesquisa();
    setShowDetails(false);
    setMicroSigaID('');
    setExpandedId(null);
  };

  const handleClose = () => {
    setEstoque(false);
    limparPesquisa();
    setShowDetails(false);
    setMicroSigaID('');
    setExpandedId(null);
  };

  const handleCloseHistoricoEstoque = () => {
    setHistoricoEstoque(false);
    limparPesquisa();
    setShowDetails(false);
    setMicroSigaID('');
    setExpandedId(null);
  };

  const handleCloseNFGestorTecnico = () => {
    setNotaFiscal(false);
    limparPesquisa();
    setShowDetails(false);
    setMicroSigaID('');
    setExpandedId(null);
  };

  const handleCloseReversaGestorTecnico = () => {
    setReversa(false);
    limparPesquisa();
    setShowDetails(false);
    setMicroSigaID('');
    setExpandedId(null);
  };

  const handleCloseSolicitacaoCadastroTecnico = () => {
    setSolicitacaoCadastro(false);
    limparPesquisa();
    setShowDetails(false);
    setMicroSigaID('');
    setExpandedId(null);
  };

  const handleNF = (tz_area_atendimento_microsigaid, nomeTecnico) => {
    setNotaFiscal(true);
    setShowDetails(true);
    setNomeTecnico(nomeTecnico);
    setMicroSigaID(tz_area_atendimento_microsigaid);
  };

  const handleReversa = (tz_area_atendimento_microsigaid, nomeTecnico) => {
    setReversa(true);
    setShowDetails(true);
    setNomeTecnico(nomeTecnico);
    setMicroSigaID(tz_area_atendimento_microsigaid);
  };

  const handleSolicitacaoCadastroTecnico = (tz_area_atendimento_microsigaid, nomeTecnico) => {
    setSolicitacaoCadastro(true);
    setShowDetails(true);
    setNomeTecnico(nomeTecnico);
    setMicroSigaID(tz_area_atendimento_microsigaid);
  };

  const delayPesquisa = debounce((nome) => {
    dadosTecnicos(0, pageSize, nome);
  }, 700);

  const pesquisarDados = (event) => {
    const nome = event.target.value;
    setPesquisa(nome);
    delayPesquisa(nome);
  };

  const mudarPagina = (event, newPage) => {
    setPage(newPage);
    dadosTecnicos(newPage, pageSize, pesquisa);
  };

  const mudarTamanhoPagina = (event) => {
    const newSize = parseInt(event.target.value, 10);
    setPageSize(newSize);
    setPage(0);
    dadosTecnicos(0, newSize, pesquisa);
  };

  const limparPesquisa = () => {
    setPesquisa('');
    dadosTecnicos(0, pageSize, '');
  };

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      await dadosTecnicos(0, pageSize, pesquisa);
      setPermissaoGestor(authService.getUserInfo().usuario.gestor);
      setTableKey(tableKey + 1);
      setLoading(false);
    };

    fetchData();
  }, [mobile, pageSize]);

  useEffect(() => {
    return () => {
      delayPesquisa.cancel();
    };
  }, []);

  if (loading) {
    return (
      <div className={classes.loadingContainer}>
        <CircularProgress />
      </div>
    );
  }

  return (
    <>
      {permissaoGestor ? (
        <>
          {!showDetails && (
            <>
              <MenuComponent />
              <div style={{ margin: 30 }}>
                <h3 className={classes.tituloStyle}>Usuários</h3>
                <h4 className={classes.subtituloStyle}>
                  <span
                    onClick={() => (window.location.href = '/gestor')}
                    className={classes.linkStyle}
                    style={{ cursor: 'pointer' }}
                  >
                    Home
                  </span>{' '}
                  {'>'} Usuários
                </h4>
                <div style={{ display: 'flex', justifyContent: 'flex-end', marginBottom: 16 }}>
                  <TextField
                    label="Pesquisar técnico"
                    variant="outlined"
                    value={pesquisa}
                    onChange={pesquisarDados}
                    style={{ width: mobile ? '100%' : '50%' }}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          {pesquisa ? (
                            <IconButton onClick={limparPesquisa}>
                              <ClearIcon />
                            </IconButton>
                          ) : (
                            <SearchIcon />
                          )}
                        </InputAdornment>
                      ),
                    }}
                  />
                </div>

                <Paper className={classes.paper}>
                  {carregandoTable ? (
                    <div className={classes.loadingContainer}>
                      <CircularProgress />
                    </div>
                  ) : (
                    <div key={tableKey} style={{ overflow: 'hidden' }}>
                      {tecnicos.length === 0 ? (
                        <Typography
                          variant="body1"
                          align="center"
                          style={{ padding: '16px', color: '#555' }}
                        >
                          Não há técnicos cadastrados para o gestor
                        </Typography>
                      ) : (
                        <Grid container spacing={1} alignItems="stretch">
                          {tecnicos.map((tecnico) => (
                            <Grid item key={tecnico.id} xs={12} md={3} style={{ width: '100%', maxWidth: '100%' }}>
                              <Paper className={classes.gridItem} style={{ padding: '16px', flex: '1 0 auto' }}>
                                <Typography className={classes.itemTexto}><strong>{tecnico.nome}</strong></Typography>
                                <Typography className={classes.itemTexto}>{tecnico.login}</Typography>
                                <Typography className={classes.itemTexto}>Código: {tecnico.id}</Typography>
                                <Accordion
                                  expanded={expandedId === tecnico.id}
                                  onChange={() => handleExpand(tecnico.id)}
                                  style={{ marginTop: 10 }}
                                >
                                  <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                                    <Typography>Visão Gestor</Typography>
                                  </AccordionSummary>
                                  <AccordionDetails>
                                    <Grid container spacing={2}>
                                      <Grid item xs={12}>
                                        <BlueButton
                                          onClick={() =>
                                            handleEstoque(tecnico.tz_area_atendimento_microsigaid, tecnico.nome)
                                          }
                                          startIcon={<InventoryIcon />}
                                          variant="contained"
                                          size="large"
                                          fullWidth
                                          style={{ height: 55 }}
                                          disabled={tecnico.possuiEstoqueLiberado === false}
                                        >
                                          <span style={{ fontSize: '0.7rem' }}>Estoque</span>
                                        </BlueButton>
                                      </Grid>
                                      <Grid item xs={12}>
                                        <BlueButton
                                          onClick={() =>
                                            handleHistoricoEstoque(tecnico.tz_area_atendimento_microsigaid, tecnico.nome)
                                          }
                                          startIcon={<TimelineIcon />}
                                          variant="contained"
                                          size="large"
                                          fullWidth
                                          style={{ height: 55 }}
                                          disabled={tecnico.possuiEstoqueLiberado === false}
                                        >
                                          <span style={{ fontSize: '0.7rem' }}>Histórico de Estoque</span>
                                        </BlueButton>
                                      </Grid>
                                      <Grid item xs={12}>
                                        <BlueButton
                                          onClick={() =>
                                            handleNF(tecnico.tz_area_atendimento_microsigaid, tecnico.nome)
                                          }
                                          startIcon={<AssignmentIcon />}
                                          variant="contained"
                                          size="large"
                                          fullWidth
                                          style={{ height: 55 }}
                                          disabled={tecnico.classificacaoPrestador === 2}
                                        >
                                          <span style={{ fontSize: '0.7rem' }}>Notas Fiscais</span>
                                        </BlueButton>
                                      </Grid>
                                      <Grid item xs={12}>
                                        <BlueButton
                                          onClick={() =>
                                            handleOrdemServico(tecnico.tz_area_atendimento_microsigaid, tecnico.nome)
                                          }
                                          startIcon={<LocalShippingIcon />}
                                          variant="contained"
                                          size="large"
                                          fullWidth
                                          style={{ height: 55 }}
                                        >
                                          <span style={{ fontSize: '0.7rem' }}>Ordens de Serviço</span>
                                        </BlueButton>
                                      </Grid>
                                      <Grid item xs={12}>
                                        <BlueButton
                                          onClick={() =>
                                            handleReversa(tecnico.tz_area_atendimento_microsigaid, tecnico.nome)
                                          }
                                          startIcon={<LockOpenIcon />}
                                          variant="contained"
                                          size="large"
                                          fullWidth
                                          style={{ height: 55 }}
                                          disabled={tecnico.classificacaoPrestador === 2 || tecnico.possuiEstoqueLiberado === false}
                                        >
                                          <span style={{ fontSize: '0.7rem' }}>Solicitação de Reversa</span>
                                        </BlueButton>
                                      </Grid>
                                      <Grid item xs={12}>
                                        <BlueButton
                                          onClick={() =>
                                            handleSolicitacaoCadastroTecnico(tecnico.tz_area_atendimento_microsigaid, tecnico.nome)
                                          }
                                          startIcon={<GroupIcon />}
                                          variant="contained"
                                          size="large"
                                          fullWidth
                                          style={{ height: 55 }}
                                        >
                                          <span style={{ fontSize: '0.7rem' }}>Cadastro de Técnicos</span>
                                        </BlueButton>
                                      </Grid>
                                    </Grid>
                                  </AccordionDetails>
                                </Accordion>
                              </Paper>
                            </Grid>
                          ))}
                        </Grid>
                      )}

                      <Grid
                        container
                        justifyContent="center"
                        alignItems="center"
                        spacing={1}
                        style={{ marginTop: 10 }}
                      >
                        <Grid item>
                          <CustomTablePagination
                            onPageChange={mudarPagina}
                            page={page}
                            count={totalDados}
                            rowsPerPage={pageSize}
                            quantidadeItens={totalDados}
                            onRowsPerPageChange={mudarTamanhoPagina}
                          />
                        </Grid>
                      </Grid>
                    </div>
                  )}
                </Paper>
              </div>
            </>
          )}
        </>
      ) : (
        <>
          <MenuComponent />
          <div style={{ margin: 30 }}>
            <h3 className={classes.tituloStyle}>Usuários</h3>
            <h4 className={classes.subtituloStyle}>
              <span
                onClick={() => (window.location.href = '/gestor')}
                className={classes.linkStyle}
                style={{ cursor: 'pointer' }}
              >
                Home
              </span>{' '}
              {'>'} Usuários
            </h4>

            <Paper className={classes.paper}>
              <Grid container justifyContent="center">
                <h2 className={classes.mensagemStyle} style={{ padding: 30 }}>
                  Você não tem permissão para acessar esta página!
                </h2>
              </Grid>
            </Paper>
          </div>
        </>
      )}
      {ordemServico && showDetails && (
        <ServicosPage
          microSigaID={microSigaID}
          handleCloseOrdemServico={handleCloseOrdemServico}
          nomeTecnico={nomeTecnico}
        />
      )}
      {estoque && showDetails && (
        <EstoquePage microSigaID={microSigaID} handleClose={handleClose} nomeTecnico={nomeTecnico} idTecnico={idTecnico} />
      )}
      {notaFiscal && showDetails && (
        <NotasFiscaisPage
          microSigaID={microSigaID}
          handleCloseNFGestorTecnico={handleCloseNFGestorTecnico}
          nomeTecnico={nomeTecnico}
        />
      )}
      {reversa && showDetails && (
        <EstoqueSolicitacaReversaPage
          microSigaID={microSigaID}
          handleCloseReversaGestorTecnico={handleCloseReversaGestorTecnico}
          nomeTecnico={nomeTecnico}
        />
      )}
      {solicitacaoCadastro && showDetails && (
        <PerfilTecnicoPage
          microSigaID={microSigaID}
          handleCloseSolicitacaoCadastroestorTecnico={handleCloseSolicitacaoCadastroTecnico}
          nomeTecnico={nomeTecnico}
        />
      )}
      {historicoEstoque && showDetails && (
        <HistoricoEstoquePage
          microSigaID={microSigaID}
          handleCloseHistoricoEstoque={handleCloseHistoricoEstoque}
          nomeTecnico={nomeTecnico}
        />
      )}
    </>
  );
}
