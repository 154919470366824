import React from 'react';
import {
	Dialog,
	DialogActions,
	DialogContent,
	DialogTitle,
	Divider,
	Grid,
	IconButton,
	Button
} from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import BlueButton from '../../components/button/BlueButton';

export function AgendamentoDialog({ handleClose, atividadeServico, handleOrdemServico, handleDetalhesOrdemServico }) {
	function formatarDataHora(dataHoraString) {
		var dataHora = new Date(dataHoraString);

		// Subtrai 3 horas da data
		dataHora.setHours(dataHora.getHours() - 3);

		function zeroAEsquerda(numero) {
			return numero < 10 ? '0' + numero : numero;
		}

		var dia = zeroAEsquerda(dataHora.getDate());
		var mes = zeroAEsquerda(dataHora.getMonth() + 1);
		var ano = dataHora.getFullYear();
		var hora = zeroAEsquerda(dataHora.getHours());
		var minutos = zeroAEsquerda(dataHora.getMinutes());
		var segundos = zeroAEsquerda(dataHora.getSeconds());

		var dataHoraFormatada = dia + '/' + mes + '/' + ano + ' ' + hora + ':' + minutos + ':' + segundos;

		return dataHoraFormatada;
	}

	return (
		<Dialog
			aria-labelledby="customized-dialog-title"
			onClose={(event, reason) => {
				if (reason !== 'backdropClick') {
					handleClose
				}
			}}
			open={true}
			style={{ marginTop: 35, marginBottom: 10 }}
		>
			<DialogTitle>
				<Grid container alignItems="center">
					<Grid item xs={10} sm={11}>
						Agendamento {atividadeServico?.na}
					</Grid>
					<Grid item xs={2} sm={1}>
						<IconButton onClick={handleClose}>
							<CloseIcon />
						</IconButton>
					</Grid>
				</Grid>
			</DialogTitle>
			<DialogContent dividers>
				<Grid container spacing={2}>
					<Grid item xs={6}>
						<strong>NA:</strong> {atividadeServico?.na}
					</Grid>
					<Grid item xs={6} sm={6}>
						<strong>Status:</strong> {atividadeServico?.descStatus}
					</Grid>
					<Grid item xs={12}>
						<strong>Cliente:</strong> {atividadeServico?.nomeCliente}
					</Grid>
					<Grid item xs={12}>
						<strong>Solicitante:</strong> {atividadeServico?.solicitanteNome}
					</Grid>
					<Grid item xs={12}>
						<strong>Contato solicitante:</strong> {atividadeServico?.solicitanteTelefone}
					</Grid>
					<Grid item xs={12} sm={6}>
						<strong>Serviço:</strong> {atividadeServico?.servico}
					</Grid>

					<Grid item xs={12}>
						<strong>Assunto:</strong> {atividadeServico?.assunto}
					</Grid>
					<Grid item xs={6} sm={6}>
						<strong>Início:</strong>{' '}
						{atividadeServico?.inicio ? formatarDataHora(atividadeServico?.inicio) : ''}
					</Grid>
					<Grid item xs={6} sm={6}>
						<strong>Término:</strong>{' '}
						{atividadeServico?.termino ? formatarDataHora(atividadeServico?.termino) : ''}
					</Grid>
					<Grid item xs={12}>
						<strong>Previsão de Início:</strong>{' '}
						{atividadeServico?.previsaoInicio ? formatarDataHora(atividadeServico?.previsaoInicio) : ''}
					</Grid>
					<Grid item xs={12}>
						<strong>Previsão de Término:</strong>{' '}
						{atividadeServico?.previsaoTermino ? formatarDataHora(atividadeServico?.previsaoTermino) : ''}
					</Grid>
				</Grid>
				<Divider style={{ marginTop: 20, marginBottom: 10 }} />
				<h3>Informações de endereço</h3>
				<Grid container spacing={2}>
					<Grid item xs={6}>
						<strong>Logradouro:</strong> {atividadeServico?.enderecoRua}
					</Grid>
					<Grid item xs={6} sm={6}>
						<strong>Número:</strong> {atividadeServico?.endereco_Numero}
					</Grid>
					<Grid item xs={6} sm={6}>
						<strong>CEP:</strong> {atividadeServico?.enderecoCepIdName}
					</Grid>
					<Grid item xs={6} sm={6}>
						<strong>Bairro:</strong> {atividadeServico?.enderecoBairro}
					</Grid>
					<Grid item xs={6} sm={6}>
						<strong>Cidade:</strong> {atividadeServico?.enderecoCidadeIdName}
					</Grid>
					<Grid item xs={6} sm={6}>
						<strong>Estado:</strong> {atividadeServico?.enderecoEstadoIdName}
					</Grid>
					<Grid item xs={12} sm={6}>
						<strong>Complemento:</strong> {atividadeServico?.enderecoComplemento}
					</Grid>
				</Grid>
			</DialogContent>

			<DialogActions style={{ justifyContent: 'space-around' }}>
				<Button
					onClick={handleClose}
					variant="contained"
					size="large"
					fullWidth
					style={{ marginBottom: '10px', marginTop: '10px', width: '40%', height: 55 }}>
					Voltar
				</Button>
				{atividadeServico.inicio ? (
					<BlueButton
						onClick={() => handleDetalhesOrdemServico(atividadeServico)}
						variant="contained"
						color="primary"
						size="large"
						fullWidth
						style={{ marginBottom: '10px', marginTop: '10px', width: '40%', height: 55 }}>
						Detalhes
					</BlueButton>
				) : (
					<BlueButton
						onClick={() => handleOrdemServico(atividadeServico)}
						variant="contained"
						color="primary"
						size="large"
						fullWidth
						disabled={atividadeServico.descStatus == 'Concluido'}
						style={{ marginBottom: '10px', marginTop: '10px', width: '40%', height: '40px' }}
					>
						Iniciar
					</BlueButton>
				)}
			</DialogActions>
		</Dialog>
	);
}
