import React, { useEffect, useState } from 'react';
import {
    Paper, Grid, TextField, Box,
    CircularProgress, Button, Typography,
    InputAdornment
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import authService from '../Services/auth/auth-service';
import estoqueService from '../Services/Estoque/estoque-service';
import MenuComponent from '../components/menu/menu-component';
import { useMediaQuery, useTheme } from '@material-ui/core';
import usuarioService from '../Services/Usuario/usuario-service';
import IconButton from '@material-ui/core/IconButton';
import SearchIcon from '@material-ui/icons/Search';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import CloseIcon from '@material-ui/icons/Close';
import CustomTablePagination from '../components/pagination/pagination-component';

const useStyles = makeStyles((theme) => ({
    mensagemStyle: {
        fontFamily: 'Mont, sans-serif',
        color: '#209BDE',
        fontSize: '22px',
        textAlign: 'start',
        padding: '0 0px',
    },
    paper: {
        marginBottom: 20,
        display: 'flex',
        flexDirection: 'column',
        padding: '15px 0px 15px',
        borderRadius: 10,
    },
    searchContainerDesktop: {
        display: 'flex',
        justifyContent: 'flex-end',
        alignItems: 'center',
        width: '30%',
        marginBottom: theme.spacing(2),
    },
    searchContainerMobile: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        width: '100%',
        marginBottom: theme.spacing(2),
    },
    searchField: {
        paddingRight: theme.spacing(1),
        [theme.breakpoints.down('sm')]: {
            width: '100%',
            marginBottom: theme.spacing(2),
        },
        [theme.breakpoints.up('md')]: {
            width: '300px',
        },
    },
    tituloStyle: {
        fontFamily: 'Mont, sans-serif',
        color: '#209BDE',
        fontSize: '22px',
        textAlign: 'start',
        padding: '0 0px',
        marginBottom: '5px',
    },
    subtituloStyle: {
        fontFamily: 'Mont, sans-serif',
        color: '#7F7F7F',
        fontSize: '16px',
        fontWeight: 'inherit',
        textAlign: 'start',
        padding: '0 0px',
        marginTop: '0px',
    },
    blueButton: {
        [theme.breakpoints.up('sm')]: {
            position: 'absolute',
            bottom: '10px',
            right: '10px',
            width: '30%',
        },
    },
    loadingContainer: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        height: '100vh',
    },
    paginaContainer: {
        margin: '0 auto',
        maxWidth: '95%',
    },
    paginationContainer: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        marginTop: theme.spacing(2),
        gap: theme.spacing(1),
        width: '100%',
    },
    paginationButton: {
        margin: theme.spacing(0, 1),
    },
    itemTexto: {
        fontFamily: 'Mont-Regular, sans-serif',
        fontSize: '16px',
    },
}));

export default function HistoricoEstoquePage({ microSigaID, handleCloseHistoricoEstoque, nomeTecnico, idTecnico }) {
    const classes = useStyles();
    const [estoqueItens, setEstoque] = useState([]);
    const permissaoTCL = authService.getUserInfo().usuario.classificacaoPrestador;
    const [usuarioGestor, setUsuarioGestor] = useState(false);
    const [tableKey, setTableKey] = useState(0);
    const [usuarioEstoqueAtivo, setUsuarioEstoqueAtivo] = useState(false);
    const [carregandoTable, setCarregandoTable] = useState(false);
    const [loading, setLoading] = useState(true);
    const [page, setPage] = useState(0);
    const [searchTerm, setSearchTerm] = useState('');
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
    const [pageSizeSerial, setPageSizeSerial] = useState(8);
    const [pageSize, setPageSize] = useState(isMobile ? 8 : 8);
    const [totalDados, setTotalDados] = useState(0);
    const [pesquisa, setPesquisa] = useState('');
    const isDesktop = useMediaQuery('(min-width:430px)');

    useEffect(() => {
        setPageSizeSerial(8);
    }, [isMobile]);

    const handleProductSearch = (term) => {
        setPesquisa(term);
        setPage(0);
        estoque(0, pageSize, term);
    };

    const buscarUsuarioEstoqueAtivo = async () => {
        let idUsuario = idTecnico || authService.getUserInfo().usuario.id;
        const response = await usuarioService.usuarioEstoqueAtivo(idUsuario);
        if (response.error) {
            return;
        } else {
            setUsuarioEstoqueAtivo(response);
        }
    };

    useEffect(() => {
        const fetchData = async () => {
            setLoading(true);
            await estoque(page, pageSize, pesquisa);
            await buscarUsuarioEstoqueAtivo();
            setUsuarioGestor(authService.getUserInfo().usuario.gestor);
            setTableKey((prevKey) => prevKey + 1);
            setLoading(false);
        };
        fetchData();
    }, [isMobile, isDesktop, page, pageSize]);

    const mudarPagina = (event, newPage) => {
        setPage(newPage);
        estoque(newPage, pageSize, searchTerm);
    };

    const mudarTamanhoPagina = (event) => {
        const newPageSize = parseInt(event.target.value, 10);
        setPageSize(newPageSize);
        setPage(0);
        estoque(0, newPageSize, searchTerm);
    };

    const estoque = async (currentPage = 0, currentPageSize = 6, currentPesquisa = '') => {
        setCarregandoTable(true);
        let area = microSigaID || authService.getUserInfo().usuario.tz_area_atendimento_microsigaid;

        const dados = {
            AreaAtendimentoId: area,
            Limite: currentPageSize,
            Pagina: currentPage,
            Pesquisa: currentPesquisa || '',
        };

        try {
            let estoqueLista = await estoqueService.listarEstoqueConsolidado(dados);

            if (estoqueLista && estoqueLista.dados) {
                let estoqueAgrupado = estoqueLista.dados.reduce((acc, item) => {
                    const produtoKey = `${item.produtoNome}`;
                    acc[produtoKey] = {
                        produto: item.produtoNome,
                        codProduto: item.produtoCodigo,
                        recusadoTecnico: item.quantidadeRecusadoTecnico,
                        reversa: item.quantidadeReversa,
                        devolver: item.quantidadeDevolver,
                        reservado: item.quantidadeReservado,
                        disponivel: item.quantidadeDisponivel,
                        recusadoFabrica: item.quantidadeRecusadoFabrica,
                    };
                    return acc;
                }, {});

                setEstoque(Object.values(estoqueAgrupado));
                setTotalDados(estoqueLista.total);
            } else {
                setEstoque([]);
                setTotalDados(0);
            }
        } catch (error) {
            console.error("Erro ao buscar estoque:", error);
        } finally {
            setCarregandoTable(false);
        }
    };

    if (loading) {
        return (
            <div className={classes.loadingContainer}>
                <CircularProgress />
            </div>
        );
    }

    return (
        <>
            <MenuComponent />
            <div style={{ margin: 30 }}>
                <h3 className={classes.tituloStyle}>Histórico de Estoque</h3>
                <h4 className={classes.subtituloStyle}>
                    <span
                        onClick={() => {
                            const userInfo = authService.getUserInfo();
                            if (userInfo.usuario.gestor) {
                                window.location.assign('/gestor');
                            } else {
                                window.location.assign('/agenda');
                            }
                        }}
                        className={classes.linkStyle}
                        style={{ cursor: 'pointer' }}
                    >
                        Home
                    </span>
                    {' > '}
                    Estoque
                    {' > '}
                    Histórico de Estoque
                    {authService.getUserInfo().usuario.gestor === true &&
                        nomeTecnico &&
                        nomeTecnico.trim() !== '' ? (
                        <>
                            {' > '}
                            {nomeTecnico}
                        </>
                    ) : null}
                </h4>

                {authService.getUserInfo().usuario.gestor === true &&
                    nomeTecnico &&
                    nomeTecnico.trim() !== '' ? (
                    <Grid item xs={3} sm={1} md={1}>
                        <Button
                            onClick={handleCloseHistoricoEstoque}
                            style={{ color: '#209BDE' }}
                            startIcon={<ArrowBackIosIcon />}
                        >
                            Voltar
                        </Button>
                    </Grid>
                ) : null}

                {permissaoTCL !== 2 && usuarioEstoqueAtivo === true ? (
                    <>
                        <Grid container justifyContent="flex-end">
                            <Grid item xs={isMobile ? 12 : 5}>
                                <TextField
                                    label="Pesquisar"
                                    variant="outlined"
                                    fullWidth
                                    value={searchTerm}
                                    onChange={(e) => {
                                        const term = e.target.value;
                                        setSearchTerm(term);
                                        handleProductSearch(term);
                                    }}
                                    style={{ marginBottom: '16px' }}
                                    InputProps={{
                                        endAdornment: (
                                            <>
                                                {searchTerm ? (
                                                    <IconButton
                                                        onClick={() => {
                                                            setSearchTerm('');
                                                            handleProductSearch('');
                                                        }}
                                                        size="small"
                                                    >
                                                        <CloseIcon />
                                                    </IconButton>
                                                ) : (
                                                    <InputAdornment position="end">
                                                        <SearchIcon />
                                                    </InputAdornment>
                                                )}
                                            </>
                                        ),
                                    }}
                                />
                            </Grid>
                        </Grid>

                        <Paper className={classes.paper}>
                            <div style={{ padding: '16px' }}>
                                {carregandoTable ? (
                                    <div className={classes.loadingContainer}>
                                        <CircularProgress />
                                    </div>
                                ) : estoqueItens.length === 0 ? (
                                    <Grid container justifyContent="center" alignItems="center" >
                                        <Typography variant="subtitle1" color="textSecondary">
                                            Não há itens para exibir.
                                        </Typography>
                                    </Grid>
                                ) : (
                                    <Grid container spacing={1}>
                                        {estoqueItens.length > 0 ? (
                                            estoqueItens.map((item, index) => (
                                                <Grid item key={index} xs={12} md={3} style={{ width: '100%', maxWidth: '100%' }}>
                                                    <Paper
                                                        style={{
                                                            padding: '16px',
                                                            cursor: 'pointer',
                                                            display: 'flex',
                                                            flexDirection: 'column',
                                                            justifyContent: 'space-between',
                                                            minHeight: '225px',
                                                            height: '100%'
                                                        }}
                                                    >
                                                        <Grid style={{ display: 'flex', flexDirection: 'column', flexGrow: 1 }}>
                                                            <Typography className={classes.itemTexto} style={{ marginBottom: 10 }}>{item.produto}</Typography>
                                                            <Typography className={classes.itemTexto}><strong>Reservado:</strong> {item.reservado}</Typography>
                                                            <Typography className={classes.itemTexto}><strong>Devolver:</strong> {item.devolver}</Typography>
                                                            <Typography className={classes.itemTexto}><strong>Reversa:</strong> {item.reversa}</Typography>
                                                            <Typography className={classes.itemTexto}><strong>Recusado pelo Técnico:</strong> {item.recusadoTecnico}</Typography>
                                                            <Typography className={classes.itemTexto}><strong>Recusado pela Fábrica:</strong> {item.recusadoFabrica}</Typography>
                                                        </Grid>
                                                    </Paper>
                                                </Grid>
                                            ))
                                        ) : (
                                            <Grid item xs={12}>
                                                <Typography align="center">Nenhum registro para exibir</Typography>
                                            </Grid>
                                        )}

                                        <Grid container justifyContent="center" alignItems="center" spacing={1} style={{ marginTop: 10 }}>
                                            <Grid item>
                                                {totalDados > 0 && (
                                                    <CustomTablePagination
                                                        count={totalDados}
                                                        page={page}
                                                        quantidadeItens={totalDados}
                                                        rowsPerPage={pageSize}
                                                        onPageChange={mudarPagina}
                                                        onRowsPerPageChange={mudarTamanhoPagina}
                                                    />
                                                )}
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                )}
                            </div>
                        </Paper>
                    </>
                ) : (
                    <>
                        <Paper className={classes.paper}>
                            <Grid container justifyContent="center">
                                {authService.getUserInfo().usuario.gestor === true && usuarioEstoqueAtivo === false ? (
                                    <h2 className={classes.mensagemStyle} style={{ padding: 30 }}>O usuário não possui o estoque liberado!</h2>
                                ) : (
                                    <h2 className={classes.mensagemStyle} style={{ padding: 30 }}>Você não tem permissão para acessar esta página!</h2>
                                )}
                            </Grid>
                        </Paper>
                    </>
                )}
            </div>
        </>
    );
}
