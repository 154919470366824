import React, { useEffect, useState } from 'react';
import {
  Paper, Grid, TextField, Divider, Box,
  CircularProgress, Button, Typography, Dialog,
  DialogActions, DialogContent, DialogTitle, InputAdornment
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import authService from '../Services/auth/auth-service';
import estoqueService from '../Services/Estoque/estoque-service';
import MenuComponent from '../components/menu/menu-component';
import { useMediaQuery, useTheme } from '@material-ui/core';
import usuarioService from '../Services/Usuario/usuario-service';
import IconButton from '@material-ui/core/IconButton';
import SearchIcon from '@material-ui/icons/Search';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import AssignmentIcon from '@material-ui/icons/Assignment';
import CloseIcon from '@material-ui/icons/Close';
import CustomTablePagination from '../components/pagination/pagination-component';
import BlueButton from '../components/button/BlueButton';

const useStyles = makeStyles((theme) => ({
  mensagemStyle: {
    fontFamily: 'Mont, sans-serif',
    color: '#209BDE',
    fontSize: '22px',
    textAlign: 'start',
    padding: '0 0px',
  },
  paper: {
    marginBottom: 20,
    display: 'flex',
    flexDirection: 'column',
    padding: '15px 0px 15px',
    borderRadius: 10,
  },
  searchContainerDesktop: {
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center',
    width: '30%',
    marginBottom: theme.spacing(2),
  },
  searchContainerMobile: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: '100%',
    marginBottom: theme.spacing(2),
  },
  searchField: {
    paddingRight: theme.spacing(1),
    [theme.breakpoints.down('sm')]: {
      width: '100%',
      marginBottom: theme.spacing(2),
    },
    [theme.breakpoints.up('md')]: {
      width: '300px',
    },
  },
  tituloStyle: {
    fontFamily: 'Mont, sans-serif',
    color: '#209BDE',
    fontSize: '22px',
    textAlign: 'start',
    padding: '0 0px',
    marginBottom: '5px',
  },
  subtituloStyle: {
    fontFamily: 'Mont, sans-serif',
    color: '#7F7F7F',
    fontSize: '16px',
    fontWeight: 'inherit',
    textAlign: 'start',
    padding: '0 0px',
    marginTop: '0px',
  },
  blueButton: {
    [theme.breakpoints.up('sm')]: {
      position: 'absolute',
      bottom: '10px',
      right: '10px',
      width: '30%',
    },
  },
  loadingContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: '100vh',
  },
  paginaContainer: {
    margin: '0 auto',
    maxWidth: '95%',
  },
  paginationContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    marginTop: theme.spacing(2),
    gap: theme.spacing(1),
    width: '100%',
  },
  paginationButton: {
    margin: theme.spacing(0, 1),
  },
  itemTexto: {
    fontFamily: 'Mont-Regular, sans-serif',
    fontSize: '16px',
  },
}));

export default function EstoquePage({ microSigaID, handleClose, nomeTecnico, idTecnico }) {
  const classes = useStyles();
  const [estoqueItens, setEstoque] = useState([]);
  const permissaoTCL = authService.getUserInfo().usuario.classificacaoPrestador;
  const [usuarioGestor, setUsuarioGestor] = useState(false);
  const [tableKey, setTableKey] = useState(0);
  const [usuarioEstoqueAtivo, setUsuarioEstoqueAtivo] = useState(false);
  const [carregandoTable, setCarregandoTable] = useState(false);
  const [loading, setLoading] = useState(true);
  const [openModalSeriais, setOpenModalSeriais] = useState(false);
  const [carregandoTableSeriais, setCarregandoTableSeriais] = useState(false);
  const [seriais, setSeriais] = useState([]);
  const [totalDadosSerial, setTotalDadosSerial] = useState(0);
  const [page, setPage] = useState(0);
  const [pageSerial, setPageSerial] = useState(0);
  const [searchTerm, setSearchTerm] = useState('');
  const theme = useTheme();
  const [filteredProducts, setFilteredProducts] = useState([]);
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const [pageSizeSerial, setPageSizeSerial] = useState(8);
  const [pageSize, setPageSize] = useState(isMobile ? 8 : 8);
  const [totalDados, setTotalDados] = useState(0);
  const [pesquisa, setPesquisa] = useState('');
  const [pesquisaSerial, setPesquisaSerial] = useState('');
  const [codigoProduto, setCodigoProduto] = useState('');
  const isDesktop = useMediaQuery('(min-width:430px)');
  const [carregandoSerialPorProduto, setcarregandoSerialPorProduto] = useState(null);

  useEffect(() => {
    setPageSizeSerial(8);
  }, [isMobile]);

  const mudarTamanhoPaginaSerial = (event) => {
    const newPageSizeSerial = parseInt(event.target.value, 10);
    setPageSizeSerial(newPageSizeSerial);
    setPageSerial(0);
    buscarSeriaisEquipamentos(codigoProduto, 0, newPageSizeSerial, pesquisaSerial);
  };

  const mudarPaginaSerial = (event, newPage) => {
    setPageSerial(newPage);
    buscarSeriaisEquipamentos(codigoProduto, newPage, pageSizeSerial, pesquisaSerial);
  };

  const handleProductSearch = (term) => {
    setPesquisa(term);
    setPage(0);
    estoque(0, pageSize, term);
  };

  const handleSerialSearch = (term) => {
    setPesquisaSerial(term);
    setPageSerial(0);
    buscarSeriaisEquipamentos(codigoProduto, 0, pageSizeSerial, term);
  };

  const buscarUsuarioEstoqueAtivo = async () => {
    let idUsuario = idTecnico || authService.getUserInfo().usuario.id;
    const response = await usuarioService.usuarioEstoqueAtivo(idUsuario);
    if (response.error) {
      return;
    } else {
      setUsuarioEstoqueAtivo(response);
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      await estoque(page, pageSize, pesquisa);
      await buscarUsuarioEstoqueAtivo();
      setUsuarioGestor(authService.getUserInfo().usuario.gestor);
      setTableKey((prevKey) => prevKey + 1);
      setLoading(false);
    };
    fetchData();
  }, [isMobile, isDesktop, page, pageSize]);

  const carregarSeriais = async () => {
    if (!codigoProduto) {
      return;
    }

    setCarregandoTableSeriais(true);

    try {
      const response = await buscarSeriaisEquipamentos(codigoProduto, pageSerial, pageSizeSerial, pesquisaSerial);

      if (response && response.dados) {
        setSeriais(response.dados);
        setFilteredProducts(response.dados);
      } else {
        setSeriais([]);
        setFilteredProducts([]);
      }
    } catch (error) {
      setSeriais([]);
      setFilteredProducts([]);
    } finally {
      setCarregandoTableSeriais(false);
    }
  };

  const mudarPagina = (event, newPage) => {
    setPage(newPage);
    estoque(newPage, pageSize, searchTerm);
  };

  const mudarTamanhoPagina = (event) => {
    const newPageSize = parseInt(event.target.value, 10);
    setPageSize(newPageSize);
    setPage(0);
    estoque(0, newPageSize, searchTerm);
  };

  const estoque = async (currentPage = 0, currentPageSize = 6, currentPesquisa = '') => {
    setCarregandoTable(true);
    let area = microSigaID || authService.getUserInfo().usuario.tz_area_atendimento_microsigaid;

    const dados = {
      AreaAtendimentoId: area,
      Limite: currentPageSize,
      Pagina: currentPage,
      Pesquisa: currentPesquisa || '',
    };

    let estoqueLista = await estoqueService.listarEstoqueConsolidado(dados);

    if (estoqueLista && estoqueLista.dados) {
      let estoqueAgrupado = estoqueLista.dados.reduce((acc, item) => {
        const produtoKey = `${item.produtoNome}`;
        acc[produtoKey] = {
          produto: item.produtoNome,
          codProduto: item.produtoCodigo,
          recusadoTecnico: item.quantidadeRecusadoTecnico,
          reversa: item.quantidadeReversa,
          devolver: item.quantidadeDevolver,
          reservado: item.quantidadeReservado,
          disponivel: item.quantidadeDisponivel,
          recusadoFabrica: item.quantidadeRecusadoFabrica,
        };
        return acc;
      }, {});

      setEstoque(Object.values(estoqueAgrupado));
      setTotalDados(estoqueLista.total);
    } else {
      setEstoque([]);
      setTotalDados(0);
    }
    setCarregandoTable(false);
  };

  const buscarSeriaisEquipamentos = async (produtoCodigo, page, pageSize, pesquisa) => {
    setCarregandoTableSeriais(true);

    let area = microSigaID || authService.getUserInfo().usuario.tz_area_atendimento_microsigaid;

    const dados = {
      AreaAtendimentoId: area,
      CodigoProduto: produtoCodigo,
      Limite: pageSize,
      Pesquisa: pesquisa,
      Pagina: page,
    };

    try {
      const response = await estoqueService.listarSeriaisEquipamentoConsolidado(dados);

      if (response && response.dados && Array.isArray(response.dados)) {
        setTotalDadosSerial(response.total || 0);
        setSeriais(response.dados);
      } else {
        setSeriais([]);
      }
    } catch (error) {
      console.error("Erro ao buscar seriais:", error);
      setSeriais([]);
    } finally {
      setCarregandoTableSeriais(false);
    }
  };

  const abrirModalSeriais = async (produtoCodigo) => {
    if (!produtoCodigo) {
      return;
    }
    setCodigoProduto(produtoCodigo);
    setcarregandoSerialPorProduto(produtoCodigo);
    setPageSerial(0);
    setOpenModalSeriais(true);
    await carregarSeriais();
    setcarregandoSerialPorProduto(null);
  };

  const fecharModalSeriais = () => {
    setOpenModalSeriais(false);
    setSeriais([]);
    setCodigoProduto('');
    setPesquisaSerial('');
  };

  useEffect(() => {
    if (openModalSeriais && codigoProduto) {
      buscarSeriaisEquipamentos(codigoProduto, pageSerial, pageSizeSerial, pesquisaSerial);
    }
  }, [pageSerial, pageSizeSerial, openModalSeriais, codigoProduto]);

  const handleInputChange = (e) => {
    const value = e.target.value;
    setPesquisa(value);
    setPage(0);
    estoque(0, pageSize, value);
  };

  const handlePreviousPageSerial = () => {
    if (pageSerial > 0) {
      setPageSerial(pageSerial - 1);
    }
  };

  const handleNextPageSerial = () => {
    if (seriais.length === pageSizeSerial) {
      setPageSerial(pageSerial + 1);
    }
  };

  const getStatus = (rowData) => {
    if (rowData.quantidadeDevolver === 1) return 'Devolver';
    if (rowData.quantidadeDisponivel === 1) return 'Disponível';
    if (rowData.quantidadeReservado === 1) return 'Reservado';
    if (rowData.quantidadeReversa === 1) return 'Reversa';
    return 'Status Desconhecido';
  };

  const truncateText = (text, maxLength) => {
    if (text.length > maxLength) {
      return text.substring(0, maxLength) + '...';
    }
    return text;
  };

  if (loading) {
    return (
      <div className={classes.loadingContainer}>
        <CircularProgress />
      </div>
    );
  }

  return (
    <>
      <MenuComponent />
      <div style={{ margin: 30 }}>
        <h3 className={classes.tituloStyle}>Meu Estoque</h3>
        <h4 className={classes.subtituloStyle}>
          <span
            onClick={() => {
              const userInfo = authService.getUserInfo();
              if (userInfo.usuario.gestor) {
                window.location.assign('/gestor');
              } else {
                window.location.assign('/agenda');
              }
            }}
            className={classes.linkStyle}
            style={{ cursor: 'pointer' }}
          >
            Home
          </span>
          {' > '}
          Estoque
          {' > '}
          Meu Estoque
          {authService.getUserInfo().usuario.gestor === true &&
            nomeTecnico &&
            nomeTecnico.trim() !== '' ? (
            <>
              {' > '}
              {nomeTecnico}
            </>
          ) : null}
        </h4>

        {authService.getUserInfo().usuario.gestor === true &&
          nomeTecnico &&
          nomeTecnico.trim() !== '' ? (
          <Grid item xs={3} sm={1} md={1}>
            <Button
              onClick={handleClose}
              style={{ color: '#209BDE' }}
              startIcon={<ArrowBackIosIcon />}
            >
              Voltar
            </Button>
          </Grid>
        ) : null}

        {permissaoTCL !== 2 && usuarioEstoqueAtivo === true ? (
          <>
            <Dialog
              aria-labelledby="customized-dialog-title"
              onClose={(event, reason) => {
                if (reason !== 'backdropClick') {
                  fecharModalSeriais();
                }
              }}
              open={openModalSeriais}
              style={{ marginTop: 45, marginBottom: 10 }}
              fullWidth
              maxWidth="sm"
            >
              <DialogTitle>
                <Grid container alignItems="center">
                  <Grid item xs={10} sm={11}>
                    Seriais
                  </Grid>
                  <Grid item xs={2} sm={1}>
                    <IconButton onClick={fecharModalSeriais}>
                      <CloseIcon />
                    </IconButton>
                  </Grid>
                </Grid>
              </DialogTitle>
              <DialogContent dividers>
                <Box display="flex" flexDirection="column" style={{ padding: '16px' }}>
                  <Grid container justifyContent="flex-end">
                    <Grid item xs={isMobile ? 12 : 5}>
                      <TextField
                        label="Pesquisar"
                        variant="outlined"
                        size="small"
                        fullWidth
                        value={pesquisaSerial}
                        onChange={(e) => {
                          const term = e.target.value;
                          setPesquisaSerial(term);
                          handleSerialSearch(term);
                        }}
                        style={{ marginBottom: '16px' }}
                        InputProps={{
                          endAdornment: (
                            <>
                              {searchTerm ? (
                                <IconButton
                                  onClick={() => {
                                    setPesquisaSerial('');
                                    handleSerialSearch('');
                                  }}
                                  size="small"
                                >
                                  <CloseIcon />
                                </IconButton>
                              ) : (
                                <InputAdornment position="end">
                                  <SearchIcon />
                                </InputAdornment>
                              )}
                            </>
                          ),
                        }}
                      />
                    </Grid>
                  </Grid>
                </Box>

                {carregandoTableSeriais ? (
                  <Box display="flex" justifyContent="center" alignItems="center" height="100%">
                    <CircularProgress />
                  </Box>
                ) : seriais.length === 0 ? (
                  <Typography variant="body1" align="center" style={{ padding: '16px' }}>
                    Nenhum registro para exibir
                  </Typography>
                ) : (
                  <>
                    {isMobile ? (
                      <Grid container spacing={2}>
                        {seriais.length > 0 ? (
                          seriais.map((serial, index) => (
                            <Grid item xs={12} key={index}>
                              <Paper elevation={1} style={{ padding: '16px', margin: '1px 0', borderRadius: '8px' }}>
                                <Typography>
                                  <strong>Serial:</strong> {serial.produtoSerial}
                                </Typography>
                                <Typography>
                                  <strong>Status:</strong> {getStatus(serial)}
                                </Typography>
                              </Paper>
                            </Grid>
                          ))
                        ) : (
                          <Grid item xs={12}>
                            <Typography variant="body1" align="center" style={{ padding: '16px' }}>
                              Nenhum registro para exibir
                            </Typography>
                          </Grid>
                        )}
                      </Grid>
                    ) : (
                      <>
                        <Grid container>
                          <Grid item xs={7}>
                            <Typography style={{ fontWeight: 'bold' }}>Serial</Typography>
                          </Grid>
                          <Grid item xs={5}>
                            <Typography style={{ fontWeight: 'bold' }}>Status</Typography>
                          </Grid>
                        </Grid>
                        <Divider style={{ margin: '8px 0' }} />
                        <Box style={{ flex: 1, overflowX: 'auto' }}>
                          {seriais.length > 0 ? (
                            seriais.map((serial, index) => (
                              <Box key={index} style={{ marginBottom: '8px' }}>
                                <Grid container key={index} style={{ padding: '8px 0' }} wrap="nowrap">
                                  <Grid item xs={7}>
                                    <Typography variant="body1" noWrap>
                                      {serial.produtoSerial}
                                    </Typography>
                                  </Grid>
                                  <Grid item xs={5}>
                                    <Typography variant="body1" noWrap>
                                      {getStatus(serial)}
                                    </Typography>
                                  </Grid>
                                </Grid>
                              </Box>
                            ))
                          ) : (
                            <Typography variant="body1" align="center" style={{ padding: '16px' }}>
                              Nenhum registro para exibir
                            </Typography>
                          )}
                        </Box>
                      </>
                    )}

                    {seriais.length > 0 && (
                      <Grid container justifyContent="center" alignItems="center" spacing={1} style={{ marginTop: 10 }}>
                        <Grid item>
                          <CustomTablePagination
                            count={totalDadosSerial}
                            page={pageSerial}
                            rowsPerPage={pageSizeSerial}
                            quantidadeItens={totalDadosSerial}
                            onPageChange={mudarPaginaSerial}
                            onRowsPerPageChange={mudarTamanhoPaginaSerial}
                          />
                        </Grid>
                      </Grid>
                    )}
                  </>
                )}
              </DialogContent>
              <DialogActions>
                <Button
                  variant="contained"
                  style={{ float: 'right', width: '40%', height: 55 }}
                  onClick={fecharModalSeriais}
                >
                  Fechar
                </Button>
              </DialogActions>
            </Dialog>

            <Box display="flex" flexDirection="column">
              <Grid container justifyContent="flex-end">
                <Grid item xs={isMobile ? 12 : 5}>
                  <TextField
                    label="Pesquisar"
                    variant="outlined"
                    size="small"
                    fullWidth
                    value={searchTerm}
                    onChange={(e) => {
                      const term = e.target.value;
                      setSearchTerm(term);
                      handleProductSearch(term);
                    }}
                    style={{ marginBottom: '16px' }}
                    InputProps={{
                      endAdornment: (
                        <>
                          {searchTerm ? (
                            <IconButton
                              onClick={() => {
                                setSearchTerm('');
                                handleProductSearch('');
                              }}
                              size="small"
                            >
                              <CloseIcon />
                            </IconButton>
                          ) : (
                            <InputAdornment position="end">
                              <SearchIcon />
                            </InputAdornment>
                          )}
                        </>
                      ),
                    }}
                  />
                </Grid>
              </Grid>
            </Box>

            <Paper className={classes.paper}>
              <div style={{ padding: '16px' }}>
                {carregandoTable ? (
                  <div className={classes.loadingContainer}>
                    <CircularProgress />
                  </div>
                ) : estoqueItens.length === 0 ? (
                  <Grid container justifyContent="center" alignItems="center" >
                    <Typography variant="subtitle1" color="textSecondary">
                      Não há itens para exibir.
                    </Typography>
                  </Grid>
                ) : (
                  <Grid container spacing={1} alignItems="stretch">
                    {estoqueItens.length > 0 ? (
                      estoqueItens.map((item, index) => (
                        <Grid item key={index} xs={12} md={3} style={{ width: '100%', maxWidth: '100%' }}>
                          <Paper style={{ padding: '16px', cursor: 'pointer', display: 'flex', minHeight: '200px' }}>
                            <Grid container alignItems="center" style={{ display: 'flex', alignItems: 'center' }}>
                              {isDesktop ? (
                                <>
                                  <Grid style={{ display: 'flex', flexDirection: 'column' }}>
                                    <Typography className={classes.itemTexto}><strong>Produto:</strong> {item.produto.length > 25 ? `${item.produto.slice(0, 25)}...` : item.produto} </Typography>
                                    <Typography className={classes.itemTexto}><strong>Disponível:</strong> {item.disponivel}</Typography>
                                  </Grid>
                                  <Grid container spacing={1} justifyContent="center">
                                    <Grid item xs={12}>
                                      <BlueButton
                                        variant="contained"
                                        color="primary"
                                        size="large"
                                        fullWidth
                                        style={{ height: 55 }}
                                        startIcon={
                                          carregandoSerialPorProduto === item.codProduto ? (
                                            <CircularProgress size={20} />
                                          ) : (
                                            <AssignmentIcon />
                                          )
                                        }
                                        onClick={() => abrirModalSeriais(item.codProduto)}
                                        disabled={carregandoSerialPorProduto === item.codProduto}
                                      >
                                        {carregandoSerialPorProduto === item.codProduto ? 'Carregando...' : 'Visualizar Seriais'}
                                      </BlueButton>
                                    </Grid>
                                  </Grid>
                                </>
                              ) : (
                                <>
                                  <Typography className={classes.itemTexto}><strong>Produto:</strong> {item.produto.length > 25 ? `${item.produto.slice(0, 25)}...` : item.produto} </Typography>
                                  <Typography className={classes.itemTexto}><strong>Disponível:</strong> {item.disponivel}</Typography>

                                  <Grid container spacing={2} justifyContent="center">
                                    <Grid item xs={12}>
                                      <BlueButton
                                        variant="contained"
                                        color="primary"
                                        size="large"
                                        fullWidth
                                        style={{ height: 55 }}
                                        startIcon={
                                          carregandoSerialPorProduto === item.codProduto ? (
                                            <CircularProgress size={20} />
                                          ) : (
                                            <AssignmentIcon />
                                          )
                                        }
                                        onClick={() => abrirModalSeriais(item.codProduto)}
                                        disabled={carregandoSerialPorProduto === item.codProduto}
                                      >
                                        {carregandoSerialPorProduto === item.codProduto ? 'Carregando...' : 'Visualizar Seriais'}
                                      </BlueButton>
                                    </Grid>
                                  </Grid>
                                </>
                              )}
                            </Grid>
                          </Paper>
                        </Grid>
                      ))
                    ) : (
                      <Grid item xs={12}>
                        <Typography align="center">Nenhum registro para exibir</Typography>
                      </Grid>
                    )}

                    <Grid container justifyContent="center" alignItems="center" spacing={1} style={{ marginTop: 10 }}>
                      <Grid item>
                        {totalDados > 0 && (
                          <CustomTablePagination
                            count={totalDados}
                            page={page}
                            quantidadeItens={totalDados}
                            rowsPerPage={pageSize}
                            onPageChange={mudarPagina}
                            onRowsPerPageChange={mudarTamanhoPagina}
                          />
                        )}
                      </Grid>
                    </Grid>
                  </Grid>
                )}
              </div>
            </Paper>
          </>
        ) : (
          <>
            <Paper className={classes.paper}>
              <Grid container justifyContent="center">
                {authService.getUserInfo().usuario.gestor === true && usuarioEstoqueAtivo === false ? (
                  <h2 className={classes.mensagemStyle} style={{ padding: 30 }}>O usuário não possui o estoque liberado!</h2>
                ) : (
                  <h2 className={classes.mensagemStyle} style={{ padding: 30 }}>Você não tem permissão para acessar esta página!</h2>
                )}
              </Grid>
            </Paper>
          </>
        )}
      </div>
    </>
  );
}
