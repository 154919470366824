import React from 'react';
import { BrowserRouter, Route, Switch, Redirect } from 'react-router-dom';
import LoginPage from './login/login-page';
import ListagemClientePage from './cliente/listagem-cliente-page';
import HomePage from './home/home-page';
import OportunidadePage from './oportunidade/oportunidade-page';
import authService from './Services/auth/auth-service';
import AgendaPage from './agenda/agenda-page';
import PerfilPage from './perfil/perfil-page';
import OrdemServicoPage from './ordemServico/ordemServico-page';
import AtividadeServicoPage from './atividade-servico/atividade-servico-page';
import EstoquePage from './estoque/estoque-page';
import EstoqueSolicitacaReversaPage from './estoque/solicitacao-reversa-page';
import NotasFiscaisPage from './notafiscal/nota-fiscal-page';
import DocumentosPage from './documentos/documentos-page';
import VendaPage from './venda/venda-page';
import ServicosPage from './servicos/servicos-page';
import NoticiasPage from './noticias/noticias-page';
import EsqueciSenhaPage from './password/esqueci-senha-page';
import TecnicoPage from './tecnico/tecnico-page';
import VistoriaPage from './vistoria/vistoriaPage';
import AprovadaPage from './vistoria/aprovado';
import RecusadaPage from './vistoria/recusado';
import ComunicacaoPage from './comunicacao/comunicacao-page';
import GestorPage from './gestor/gestor-page';
import VistoriaGestorPage from './gestor/vistoria-gestor-page';
import GestorNaPage from './gestor/na-gestor-page';
import AuditoriaPage from './auditoria/auditoria-page';
import GestorTecnicoPage from './gestor/tecnico/gestor-tecnico-page';
import PerfilTecnicoPage from './perfilTecnico/perfil-tecnico-page';
import PerfilOuroPage from './perfil/perfil-ouro';
import InventarioPage from './inventario/inventario-page';
import RedefinirSenhaPage from './redefinirSenha/redefinir-senha-page';
import LiberacaoEstoqueGestorPage from './liberacaoEstoque/liberacao-estoque-gestor-page';
import HistoricoEstoquePage from './estoque/historico-estoque-page';

const PrivateRoute = ({ component: Component, ...rest }) => {
  return (
    <Route
      {...rest}
      render={(props) =>
        authService.getUserInfo() != null || authService.getUserInfo() != undefined ? (
          <Component {...props} />
        ) : (
          <Redirect to={{ pathname: '/', state: { from: props.location } }} />
        )
      }
    />
  );
};

const Routes = () => (
  <BrowserRouter>
    <Switch>
      <Route path="/" exact={true} component={LoginPage} />
      <Route path='/health'/>
      <PrivateRoute path="/clientes" component={ListagemClientePage} />
      <PrivateRoute path="/financeiro" component={PerfilOuroPage} />
      <PrivateRoute path="/gestor" component={GestorPage} />
      <PrivateRoute path="/nagestor" component={GestorNaPage} />
      <PrivateRoute path="/home" component={HomePage} />
      <PrivateRoute path="/oportunidades" component={OportunidadePage} />
      <PrivateRoute path="/agenda" component={AgendaPage} />
      <PrivateRoute path="/ordemservico" component={OrdemServicoPage} />
      <PrivateRoute path="/atividadeservico" component={AtividadeServicoPage} />
      <PrivateRoute path="/inventario" component={InventarioPage} />
      <PrivateRoute path="/solicitacaoreversa" component={EstoqueSolicitacaReversaPage} />
      <PrivateRoute path="/manuais" component={DocumentosPage} />
      <PrivateRoute path="/estoque" component={EstoquePage} />
      <PrivateRoute path="/auditoria" component={AuditoriaPage} />
      <PrivateRoute path="/notasfiscais" component={NotasFiscaisPage} />
      <PrivateRoute path="/perfil" component={PerfilPage} />
      <PrivateRoute path="/venda" component={VendaPage} />
      <PrivateRoute path="/servicos" component={ServicosPage} />
      <PrivateRoute path="/noticia" component={NoticiasPage} />
      <PrivateRoute path="/vistoriagestor" component={VistoriaGestorPage} />
      <PrivateRoute path="/comunicacao" exact component={ComunicacaoPage} />
      <PrivateRoute path="/gestorTecnico" exact component={GestorTecnicoPage} />
      <PrivateRoute path="/perfiltecnico" exact component={PerfilTecnicoPage} />
      <PrivateRoute path="/liberacaoEstoqueGestor" exact component={LiberacaoEstoqueGestorPage} />
      <PrivateRoute path="/historicoEstoque" exact component={HistoricoEstoquePage} />
      <Route path="/recuperar-senha" component={RedefinirSenhaPage} />
      <Route path="/password" component={EsqueciSenhaPage} />
      <Route path="/tecnico" component={TecnicoPage} />
      <Route path="/vistoria" component={VistoriaPage} />
      <Route path="/aprovado" component={AprovadaPage} />
      <Route path="/recusado" component={RecusadaPage} />
    </Switch>
  </BrowserRouter>
);

export default Routes;
