import React, { useEffect, useState, useCallback } from 'react';
import moment from 'moment';
import {
    TextField,
    useMediaQuery, useTheme,
    FormControl, Box,
    Dialog, CircularProgress,
    DialogActions, DialogContent,
    DialogTitle, Button, DialogContentText, Typography
} from '@material-ui/core';
import MenuComponent from '../components/menu/menu-component';
import BlueButton from '../components/button/BlueButton';
import Autocomplete from '@material-ui/lab/Autocomplete';
import authService from '../Services/auth/auth-service';
import { Paper, Grid } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import CustomAlert from '../components/alert/CustomAlert';
import CloseIcon from '@material-ui/icons/Close';
import IconButton from '@material-ui/core/IconButton';
import CustomTablePagination from '../components/pagination/pagination-component';
import auditoriaService from '../Services/Auditoria/auditoria-service';
import PersonAddIcon from '@mui/icons-material/PersonAdd';
import liberacaoEstoqueGestorService from '../Services/liberacaoEstoque/liberacao-estoque-gestor-service';
import { CheckCircle, Block, Info, } from '@material-ui/icons';
import InputAdornment from '@material-ui/core/InputAdornment';
import SearchIcon from '@material-ui/icons/Search';
import ClearIcon from '@material-ui/icons/Clear';
import EditIcon from '@mui/icons-material/Edit';
import { debounce } from '@mui/material';

const useStyles = makeStyles((theme) => ({
    paper: {
        marginBottom: 20,
        display: 'flex',
        flexDirection: 'column',
        padding: '15px 20px 15px',
        borderRadius: 10,
    },
    tituloStyle: {
        fontFamily: 'Mont, sans-serif',
        color: '#209BDE',
        fontSize: '22px',
        textAlign: 'start',
        padding: '0 0px',
        marginBottom: '5px'
    },
    mensagemStyle: {
        fontFamily: 'Mont, sans-serif',
        color: '#209BDE',
        fontSize: '22px',
        textAlign: 'start',
        padding: '0 0px',
    },
    subtituloStyle: {
        fontFamily: 'Mont, sans-serif',
        color: '#7F7F7F',
        fontSize: '16px',
        fontWeight: 'inherit',
        textAlign: 'start',
        padding: '0 0px',
        marginTop: '0px'
    },
    loadingContainer: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        height: '100vh',
    },
}));

export default function LiberacaoEstoqueGestorPage() {
    const classes = useStyles();
    const mobile = useMediaQuery(useTheme().breakpoints.down(800));
    const [tecnicoID, setTecnicoID] = useState('');
    const [tecnicos, setTecnicos] = useState([]);
    const [page, setPage] = useState(0);
    let [pageSize, setPageSize] = useState(8);
    const [totalDados, setTotalDados] = useState(0);
    const [dadosInventario, setDadosInventario] = useState([]);
    const [carregandoTable, setCarregandoTable] = useState(false);
    const [carregandoTela, setCarregandoTela] = useState(true);
    const [carregandoCadastro, setCarregandoCadastro] = useState(false);
    const [carregandoEdicao, setCarregandoEdicao] = useState(false);
    const [permissaoGestor, setPermissaoGestor] = useState(0);
    const [tableKey, setTableKey] = useState(0);
    const [pesquisa, setPesquisa] = useState('');
    const [nomeTecnico, setNomeTecnico] = useState('');
    const [tecnicoStatus, setTecnicoStatus] = useState(false);
    const [abrirModalCadastro, setAbrirModalCadastro] = useState(false);
    const [abrirModalEditar, setAbrirModalEditar] = useState(false);
    const [alertOpen, setAlertOpen] = useState(false);
    const [alertMessage, setAlertMessage] = useState({ title: '', body: '' });
    const [alertType, setAlertType] = useState('success');

    const StatusBadge = ({ status }) => {
        let statusText = '';
        let bgColor = '';
        let IconComponent = null;

        switch (status) {
            case true:
                statusText = 'Liberado';
                bgColor = 'rgba(3, 201, 169, 1)';
                IconComponent = CheckCircle;
                break;
            case false:
                statusText = 'Bloqueado';
                bgColor = 'rgba(255, 99, 132, 1)';
                IconComponent = Block;
                break;
            default:
                statusText = 'Desconhecido';
                bgColor = '#000';
                IconComponent = Info;
                break;
        }

        return (
            <div style={{
                textAlign: 'center',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                color: 'white',
                width: '100%',
                height: 44,
                marginTop: '10px',
                borderRadius: 15,
                backgroundColor: bgColor,
            }}>
                <IconComponent style={{ marginRight: '8px', color: '#fff' }} />
                {statusText}
            </div>
        );
    };

    const abrirModalCadastroTecnico = async () => {
        setAbrirModalCadastro(true);
    }

    const fecharModalCadastroTecnico = async () => {
        setNomeTecnico('');
        setTecnicoID('');
        setAbrirModalCadastro(false);
    }

    const abrirModalEditarTecnico = async (tecnico) => {
        setNomeTecnico(tecnico.nome);
        setTecnicoStatus(tecnico.status);
        setAbrirModalEditar(true);
        setTecnicoID(tecnico.usuarioId);
    }

    const fecharModalEditarTecnico = async () => {
        setAbrirModalEditar(false);
        setNomeTecnico('');
        setTecnicoStatus('');
    }

    const handleAdicionarTecnico = async () => {
        if ((tecnicoID != null && tecnicoID !== '')) {
            setCarregandoCadastro(true);
            const dados = {
                usuarioId: tecnicoID,
            }

            const response = await liberacaoEstoqueGestorService.criarRegistroEstoqueLiberado(JSON.stringify(dados));

            if (response.error == false) {
                setAlertMessage({ title: 'Sucesso!', body: 'Técnico inserido com sucesso!' });
                setAlertType('success');
                setAlertOpen(true);
                setNomeTecnico('');
                fecharModalCadastroTecnico();
                buscarTecnicosEstoqueLiberado(page, pageSize, pesquisa);
            } else {
                setAlertMessage({ title: 'Erro!', body: response.message });
                setAlertType('error');
                setAlertOpen(true);
            }

            setCarregandoCadastro(false);
        } else {
            setAlertMessage({ title: 'Falha!', body: 'É necessário selecionar um técnico para realizar o cadastro.' });
            setAlertType('fail');
            setAlertOpen(true);
        }
    }

    const editarTecnico = async () => {
        setCarregandoEdicao(true);

        const response = await liberacaoEstoqueGestorService.editarStatusTecnicoLiberacaoEstoque(tecnicoID);
        if (response.error === false) {
            setAlertMessage({ title: 'Sucesso!', body: 'Status alterado com sucesso!' });
            setAlertType('success');
            setAlertOpen(true);
            buscarTecnicosEstoqueLiberado();
            fecharModalEditarTecnico();
        } else {
            setAlertMessage({ title: 'Erro!', body: response.message });
            setAlertType('error');
            setAlertOpen(true);

        }
        setCarregandoEdicao(false);
    }

    const buscarTecnicosEstoqueLiberado = async (page, pageSize, pesquisa) => {
        setCarregandoTable(true);
        try {
            const dados = {
                pagina: page || 0,
                limite: pageSize || 8,
                nome: pesquisa || '',
            };

            const response = await liberacaoEstoqueGestorService.tecnicosListagemEstoqueLiberado(authService.getUserInfo().usuario.localid, dados);

            if (response && response.dados) {
                setTotalDados(response.total || 0);
                setDadosInventario(response.dados);
            } else {
                setTotalDados(0);
                setDadosInventario([]);
            }
        } catch (error) {
            setAlertMessage({ title: 'Erro!', body: 'Erro ao buscar dados do inventário.' });
            setAlertType('error');
            setAlertOpen(true);
        }
        setCarregandoTable(false);
    };

    const usuariosGestor = async () => {
        const response = await auditoriaService.listarEmpresasGestor();
        if (response.error) {
            return;
        } else {
            setTecnicos(response);
        }
    };

    const delayPesquisa = useCallback(
        debounce((nome) => {
            buscarTecnicosEstoqueLiberado(0, pageSize, nome);
        }, 700), []
    );

    const valorPesquisa = (nome) => {
        if (nome !== pesquisa) {
            setPesquisa(nome);
            delayPesquisa(nome);
        }
    };

    const limparPesquisa = () => {
        setPesquisa('');
        buscarTecnicosEstoqueLiberado(page, pageSize, '');
    };

    const mudarPagina = (event, newPage) => {
        setPage(newPage);
        buscarTecnicosEstoqueLiberado(newPage, pageSize, pesquisa);
    };

    const mudarTamanhoPagina = (event) => {
        const novoTamanhoPagina = parseInt(event.target.value, 10);
        setPageSize(novoTamanhoPagina);
        setPage(0);
        buscarTecnicosEstoqueLiberado(0, novoTamanhoPagina, pesquisa);
    };

    useEffect(() => {
        setCarregandoTela(true);
        buscarTecnicosEstoqueLiberado(page, pageSize, pesquisa);
        usuariosGestor();
        setPermissaoGestor(authService.getUserInfo().usuario.gestor);
        setCarregandoTela(false);
        setTableKey(tableKey + 1)
    }, [mobile]);

    if (carregandoTela) {
        return (
            <div className={classes.loadingContainer}>
                <CircularProgress />
            </div>
        );
    }

    return (
        <>
            <MenuComponent />
            <CustomAlert
                open={alertOpen}
                message={alertMessage}
                type={alertType}
                onClose={() => setAlertOpen(false)}
            />
            {permissaoGestor === true ? (
                <>
                    <div style={{ margin: 30 }}>
                        <h3 className={classes.tituloStyle}>Liberação do Estoque</h3>
                        <h4 className={classes.subtituloStyle}>
                            <span
                                onClick={() => {
                                    const userInfo = authService.getUserInfo();
                                    if (userInfo.usuario.gestor) {
                                        window.location.assign('/gestor');
                                    } else {
                                        window.location.assign('/agenda');
                                    }
                                }}
                                className={classes.linkStyle}
                                style={{ cursor: 'pointer' }}
                            >
                                Home
                            </span> {'>'} Liberação do Estoque
                        </h4>

                        <Dialog
                            aria-labelledby="customized-dialog-title"
                            onClose={(event, reason) => {
                                if (reason !== 'backdropClick') {
                                    fecharModalCadastroTecnico();
                                }
                            }}
                            open={abrirModalCadastro}
                            style={{ marginTop: 35, marginBottom: 10 }}
                            fullWidth
                            maxWidth="sm"
                        >
                            <DialogTitle>
                                <Grid container alignItems="center">
                                    <Grid item xs={10} sm={11}>
                                        Adicionar Técnico
                                    </Grid>
                                    <Grid item xs={2} sm={1}>
                                        <IconButton onClick={fecharModalCadastroTecnico}>
                                            <CloseIcon />
                                        </IconButton>
                                    </Grid>
                                </Grid>
                            </DialogTitle>
                            <DialogContent dividers>
                                <Grid container spacing={3}>
                                    <Grid item xs={12} sm={12}>
                                        <DialogContentText>
                                            Informe o técnico que será inserido:
                                        </DialogContentText>
                                        <FormControl variant="outlined" fullWidth className="limited-select" style={{ marginBottom: '16px' }}>
                                            <Autocomplete
                                                id="tecnicoID"
                                                options={tecnicos}
                                                getOptionLabel={(option) => option.nome}
                                                value={tecnicos.find((option) => option.id === tecnicoID) || null}
                                                onChange={(event, newValue) => {
                                                    setTecnicoID(newValue ? newValue.id : '');
                                                }}
                                                renderInput={(params) => (
                                                    <TextField
                                                        {...params}
                                                        label={<span>Selecione um técnico <span style={{ color: 'red' }}> *</span></span>}
                                                        variant="outlined"
                                                        fullWidth
                                                        placeholder="Selecione um técnico"
                                                        InputLabelProps={{
                                                            htmlFor: 'tecnicoID',
                                                            shrink: true,
                                                        }}
                                                        inputProps={{
                                                            ...params.inputProps,
                                                            id: 'tecnicoID',
                                                        }}
                                                    />
                                                )}
                                            />
                                        </FormControl>
                                    </Grid>
                                </Grid>
                            </DialogContent>

                            <DialogActions style={{ justifyContent: 'space-around' }}>
                                <Button
                                    variant="contained"
                                    fullWidth
                                    disabled={carregandoCadastro}
                                    onClick={fecharModalCadastroTecnico}
                                    style={{ marginBottom: '10px', marginTop: '10px', width: '40%', height: 55 }}
                                >
                                    Cancelar
                                </Button>
                                <BlueButton
                                    variant="contained"
                                    color="primary"
                                    size="large"
                                    disabled={carregandoCadastro}
                                    onClick={handleAdicionarTecnico}
                                    startIcon={carregandoCadastro ? <CircularProgress color="primary" size={20} /> : ''}
                                    style={{ marginBottom: '10px', marginTop: '10px', width: '40%', height: 55 }}
                                >
                                    {carregandoCadastro ? 'Adicionando...' : 'Adicionar'}
                                </BlueButton>
                            </DialogActions>
                        </Dialog>

                        <Dialog
                            aria-labelledby="customized-dialog-title"
                            open={abrirModalEditar}
                            style={{ marginTop: 35, marginBottom: 10 }}
                            onClose={(event, reason) => {
                                if (reason !== 'backdropClick') {
                                    fecharModalEditarTecnico();
                                }
                            }}
                            fullWidth
                            maxWidth="sm"
                        >
                            <DialogTitle>
                                <Grid container alignItems="center">
                                    <Grid item xs={10} sm={11}>
                                        Editar Técnico
                                    </Grid>
                                    <Grid item xs={2} sm={1}>
                                        <IconButton onClick={fecharModalEditarTecnico}>
                                            <CloseIcon />
                                        </IconButton>
                                    </Grid>
                                </Grid>
                            </DialogTitle>
                            <DialogContent dividers>
                                <Grid container spacing={3}>
                                    <Grid item xs={12} sm={12}>
                                        {tecnicoStatus === true ? (
                                            <DialogContentText>
                                                Deseja <strong>bloquear</strong> o acesso ao estoque do técnico <strong>{nomeTecnico}</strong>?
                                            </DialogContentText>
                                        ) : (
                                            <DialogContentText>
                                                Deseja <strong>liberar</strong> o acesso ao estoque do técnico <strong>{nomeTecnico}</strong>?
                                            </DialogContentText>
                                        )}
                                    </Grid>
                                </Grid>
                            </DialogContent>
                            <DialogActions style={{ justifyContent: 'space-around' }}>
                                <Button
                                    variant="contained"
                                    fullWidth
                                    disabled={carregandoEdicao}
                                    onClick={fecharModalEditarTecnico}
                                    style={{ marginBottom: '10px', marginTop: '10px', width: '40%', height: 55 }}
                                >
                                    Cancelar
                                </Button>
                                <BlueButton
                                    variant="contained"
                                    color="primary"
                                    size="medium"
                                    disabled={carregandoEdicao}
                                    onClick={editarTecnico}
                                    startIcon={carregandoEdicao ? <CircularProgress color="primary" size={20} /> : ''}
                                    style={{ marginBottom: '10px', marginTop: '10px', width: '40%', height: 55 }}
                                >
                                    {carregandoEdicao ? 'Editando...' : (tecnicoStatus === true ? 'Bloquear' : 'Liberar')}
                                </BlueButton>
                            </DialogActions>
                        </Dialog>

                        <div style={{ marginBottom: 20 }}>
                            <Grid container spacing={1} direction="column">
                                <Grid container justifyContent="space-between" item xs={12} sm={12}>
                                    <BlueButton
                                        variant="contained"
                                        color="primary"
                                        size="large"
                                        onClick={abrirModalCadastroTecnico}
                                        style={{ height: 55, marginBottom: mobile ? 10 : 0, width: mobile ? '100%' : '20%' }}
                                        startIcon={<PersonAddIcon />}
                                    >
                                        Adicionar Técnico
                                    </BlueButton>
                                    <TextField
                                        label="Pesquisar"
                                        variant="outlined"
                                        value={pesquisa}
                                        onChange={(event) => valorPesquisa(event.target.value)}
                                        style={{ width: mobile ? '100%' : '40%' }}
                                        InputProps={{
                                            endAdornment: (
                                                <InputAdornment position="end">
                                                    {pesquisa ? (
                                                        <IconButton onClick={limparPesquisa}>
                                                            <ClearIcon />
                                                        </IconButton>
                                                    ) : (
                                                        <SearchIcon />
                                                    )}
                                                </InputAdornment>
                                            ),
                                        }}
                                    />
                                </Grid>
                            </Grid>
                        </div>

                        <Paper className={classes.paper}>
                            {carregandoTable ? (
                                <Grid container justifyContent="center" alignItems="center" style={{ height: '200px' }}>
                                    <CircularProgress />
                                </Grid>
                            ) : dadosInventario.length === 0 ? (
                                <Grid container justifyContent="center" alignItems="center" >
                                    <Typography variant="subtitle1" color="textSecondary">
                                        Nenhum registro encontrado.
                                    </Typography>
                                </Grid>
                            ) : (
                                <Grid container spacing={1}>
                                    {dadosInventario.map((item) => (
                                        <Grid item key={item.id} xs={12} md={3} style={{ width: '100%', maxWidth: '100%' }}>
                                            <Paper
                                                style={{
                                                    padding: '16px',
                                                    display: 'flex',
                                                    flexDirection: 'column',
                                                    justifyContent: 'space-between',
                                                    height: '100%',
                                                    boxSizing: 'border-box',
                                                }}
                                            >
                                                <Box padding={1} style={{ flexGrow: 1 }}>
                                                    <Typography component="div" variant="body2">
                                                        <strong>Nome:</strong> {item.nome}
                                                    </Typography>
                                                    <Typography component="div" variant="body2">
                                                        <strong>Data Cadastro:</strong> {moment(item.dataCadastro).format('DD/MM/YYYY HH:mm:ss')}
                                                    </Typography>
                                                    <Typography component="div" variant="body2">
                                                        <strong>Data Atualização:</strong> {moment(item.dataAtualizacao).format('DD/MM/YYYY HH:mm:ss')}
                                                    </Typography>
                                                    <Grid container spacing={1}>
                                                        <Grid item xs={12} sm={12}>
                                                            <Typography component="div" variant="body2"><StatusBadge status={item.status} /></Typography>
                                                        </Grid>
                                                        <Grid item xs={12} sm={12}>
                                                            <BlueButton
                                                                variant="contained"
                                                                color="primary"
                                                                size="large"
                                                                onClick={() => abrirModalEditarTecnico(item)}
                                                                fullWidth
                                                                startIcon={<EditIcon />}
                                                                style={{ height: 55 }}
                                                            >
                                                                <span style={{ fontSize: '0.7rem' }}>Alterar Status</span>
                                                            </BlueButton>
                                                        </Grid>
                                                    </Grid>
                                                </Box>
                                            </Paper>
                                        </Grid>
                                    ))}
                                    <Grid container justifyContent="center" alignItems="center" spacing={1} style={{ marginTop: 10 }}>
                                        {dadosInventario.length > 0 && (
                                            <Grid item>
                                                <CustomTablePagination
                                                    count={totalDados}
                                                    page={page}
                                                    rowsPerPage={pageSize}
                                                    onPageChange={mudarPagina}
                                                    quantidadeItens={totalDados}
                                                    onRowsPerPageChange={mudarTamanhoPagina}
                                                />
                                            </Grid>
                                        )}
                                    </Grid>
                                </Grid>
                            )}
                        </Paper>
                    </div>
                </>
            ) : (
                <div style={{ margin: 30 }}>
                    <h3 className={classes.tituloStyle}>Inventário</h3>
                    <h4 className={classes.subtituloStyle}>
                        <span
                            onClick={() => {
                                const userInfo = authService.getUserInfo();
                                if (userInfo.usuario.gestor) {
                                    window.location.assign('/gestor');
                                } else {
                                    window.location.assign('/agenda');
                                }
                            }}
                            className={classes.linkStyle}
                            style={{ cursor: 'pointer' }}
                        >
                            Home
                        </span> {'>'} Liberação do Estoque
                    </h4>
                    <Paper className={classes.paper}>
                        <Grid container justifyContent="center">
                            <h2 className={classes.mensagemStyle} style={{ padding: 30 }}>
                                Você não tem permissão para acessar esta página!
                            </h2>
                        </Grid>
                    </Paper>
                </div>
            )}
        </>
    );
}
